export const WEBSITE_BASE_URL = 'http://secondopinionservices.in/';
export const API_BASE_URL = 'https://sosapi.gargroup.in/';
// export const API_BASE_URL = 'http://localhost/sosportalapi/';
// export const API_BASE_URL = 'http://192.168.223.9:8089/sosportalapi/';

export const API_LOGIN = API_BASE_URL + "api/login/";
export const API_SEND_MAIL_RESET_PASSWORD = API_BASE_URL + "api/sendMailResetPassword/";
export const API_VALIDATE_RESET_TOKEN = API_BASE_URL + "api/validateResetToken/";
export const API_FORGOT_PASSWORD = API_BASE_URL + "api/forgotPassword/";
export const API_CHANGE_PASSWORD = API_BASE_URL + "api/changePassword/";

export const API_GET_DASHBOARD_DATA = API_BASE_URL + "api/getDashboardData/";
export const API_GET_ALL_NOTIFICATION_DATA = API_BASE_URL + "api/getAllNotificationData/";

export const API_USER = API_BASE_URL + "api/user/";
export const API_USER_REGISTER = API_BASE_URL + "api/userRegister/";
export const API_UPLOAD_PROFILE_IMG = API_BASE_URL + "api/uploadProfileImage/";
export const API_SEND_OTP = API_BASE_URL + "api/sendOTP";

export const API_COMPANY = API_BASE_URL + "api/company/";
export const API_COMPANY_BY_USER_ID = API_BASE_URL + "api/companyByUserId/";
export const API_UPLOAD_COMPANY_LOGO = API_BASE_URL + "api/uploadCompanyLogo/";

export const API_PROJECT = API_BASE_URL + "api/project/";
export const API_GET_PROJECT_BY_COMPANY = API_BASE_URL + "api/getProjectByCompany/";

export const API_FILE = API_BASE_URL + "api/file/";
export const API_GET_FILES_BY_PROJECT = API_BASE_URL + "api/getFilesByProject/";

export const API_QUOTATION = API_BASE_URL + "api/quotation/";
export const API_GET_QUOTATION_BY_PROJECT = API_BASE_URL + "api/getQuotationByProject/";
export const API_QUOTATION_APPROVE_REJECT = API_BASE_URL + "api/quotationApproveReject/";
export const API_GET_APPROVED_QUOTATION = API_BASE_URL + "api/getApprovedQuotation/";
export const API_PAYMENT = API_BASE_URL + "api/payment/";
export const API_GET_PAYMENT_DETAILS = API_BASE_URL + "api/getPaymentDetails/";

export const API_GET_ANALYSIS_BY_PROJECT = API_BASE_URL + "api/getAnalysisByProject/";

export const API_QUERY = API_BASE_URL + "api/query/";
export const API_GET_QUERY_BY_PROJECT = API_BASE_URL + "api/getQueryByProject/";
export const API_GET_QUERY_REPLY_BY_QUERY_CODE = API_BASE_URL + "api/getQueryReplyByQueryCode/";

export const API_EVENTLOG = API_BASE_URL + "api/eventLog";
