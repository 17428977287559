import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image, Form, ListGroup, Card, Button } from "react-bootstrap";
import Dialog from 'react-bootstrap-dialog';
import  * as Constants from "../../config/Constants";
import { UserContext } from "../context/user.context";
import Loader from '../header/Loader';
import profile_default from "../../assets/image/profile_default.png";
import { AlertContext } from "../context/Alert";
import { LocalizationContext } from "../context/localization/localization.context";

import "./profile.scss"

function PersonalDetails() {
    const { token, userId, setLocalStorage } = React.useContext(UserContext);
    const { t } = React.useContext(LocalizationContext);
    const { dialog } = React.useContext(AlertContext);
    const isEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const isNumber = /^[0-9\b]+$/;
    const [loading, setLoading] = useState(false);
    const [userData, setUserData] = useState(null);
    const [errors, setErrors] = useState(null);
    const [readOnly, setReadOnly] = useState(true);
    
    useEffect(() => {
        setLoading(true);
        fetch(Constants.API_USER + userId, { 
                method: "GET", 
                headers: { 
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + token
                }
            }) 
            .then(response => response.json()) 
            .then(
                data => {
                    setLoading(false);
                    if (data.status) {
                        setUserData(data.userData);
                    }
                }
            ); 
    }, []);

    function handleValidation(e) {
        let fields = userData;
        let errors = {};
        let formIsValid = true;

        if (!fields["first_name"] && fields["first_name"] === "") {
            formIsValid = false;
            errors["first_name"] = t("errors.first_name");
        }
        if (!fields["last_name"] && fields["last_name"] === "") {
            formIsValid = false;
            errors["last_name"] = t("errors.last_name");
        }
        if (!fields["mobile_number"] && fields["mobile_number"] === "") {
            formIsValid = false;
            errors["mobile_number"] = t("errors.mobile_number");
        } else if (!isNumber.test(fields["mobile_number"])) {
            formIsValid = false;
            errors["mobile_number"] = t("errors.only_number");
        } else if (fields["mobile_number"].length < 10) {
            formIsValid = false;
            errors["mobile_number"] = t("errors.number_10_digit");
        } 
        if (!fields["alternate_mobile_number"] && fields["alternate_mobile_number"] === "") {
        } else {
            if (!isNumber.test(fields["alternate_mobile_number"])) {
                formIsValid = false;
                errors["alternate_mobile_number"] = t("errors.only_number");
            } else if (fields["alternate_mobile_number"].length < 10) {
                formIsValid = false;
                errors["alternate_mobile_number"] = t("errors.number_10_digit");
            } 
        }
        if (!fields["email"] && fields["email"] === "") {
            formIsValid = false;
            errors["email"] = t("errors.email");
        } else if (!isEmail.test(fields["email"])) {
            formIsValid = false;
            errors["email"] = t('errors.valid_email');
        }
        setErrors(errors);
        return formIsValid;
    }

    function handleChangeFile(e) {
        setLoading(true);
        const formData = new FormData();
        formData.append("profile_image", e.target.files[0]);
        fetch(Constants.API_UPLOAD_PROFILE_IMG + userId, { 
            method: "POST", 
            body: formData, 
            headers: { 
                "Authorization": "Bearer " + token
            }
        }) 
        .then(response => response.json()) 
        .then(
            data => {
                setLoading(false);
                dialog.show({
                    body: data.message,
                    actions: [
                        Dialog.OKAction(() => {
                            if (data.status) {
                                setLocalStorage(data.token, data.userData);
                                window.location.reload(false);
                            }
                        })
                    ],
                    bsSize: 'small'
                });
            }
        ); 
    }

    function handleChange(e) {
        if (e.target.name === "mobile_number" || e.target.name === "alternate_mobile_number") {
            const onlyNums = e.target.value.replace(/[^0-9]/g, '');
            const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/,'$1$2$3');
            setUserData({ ...userData, [e.target.name]: number });
        } else {
            const { name, value } = e.target;
            setUserData({ ...userData, [name]: value });
        }
    }

    function handleUpdate(e) {
        e.preventDefault();
        if (handleValidation()) {
            setLoading(true);
            const userUpdateData = {
                "first_name": userData.first_name,
                "middle_name": userData.middle_name,
                "last_name": userData.last_name,
                "email" : userData.email,
                "personal_contact": userData.mobile_number,
                "mobile_number": userData.alternate_mobile_number,
                "address": userData.address
            }
            fetch(Constants.API_USER + userId, { 
                method: "PUT", 
                body: JSON.stringify(userUpdateData), 
                headers: { 
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": "Bearer " + token
                }
            }) 
            .then(response => response.json()) 
            .then(
                data => {
                    // .replace(/<(.|\n)*?>/g, '')
                    setLoading(false);
                    dialog.show({
                        body: data.message,
                        actions: [
                            Dialog.OKAction(() => {
                                if (data.status) {
                                    setLocalStorage(data.token, data.userData);
                                    window.location.reload(false);
                                }
                            })
                        ],
                        bsSize: 'small'
                    });
                }
            ); 
        }
    }

    const handleEdit = () => {
        setReadOnly(false);
    }
    
    return (
        <>
            <Container className="mt-cust">
                <Row>
                    <Col md="3">
                        <div className="text-center">
                            {
                                (userData && userData.profile_image !== null && userData.profile_image !== "") ? 
                                    <Image src={Constants.API_BASE_URL + userData.profile_image} thumbnail /> 
                                : 
                                    <Image src={profile_default} thumbnail /> 
                            }
                            <h6 className="mt-4">{
                                (userData) ? 
                                    <>
                                        {
                                            userData.first_name + " " + userData.last_name}
                                        <br/>
                                        <br/>
                                        <strong>User Login</strong>
                                        <p className="mt-2">{userData.email}</p>
                                    </>
                                : 
                                    ""
                            }</h6>
                            <hr/>
                            <h6 className="mt-4">{t("lable.upload_photo")}</h6>
                            <Form.Group className="text-left">
                                <Form.File label={t("lable.choose_photo")} custom onChange={handleChangeFile}/>
                            </Form.Group>
                        </div> 
                        <hr/>
                        <ListGroup as="ul" style={{marginBottom: "5rem"}}>
                            <ListGroup.Item as="li" className="div-active">
                                <i className="fa fa-address-book" aria-hidden="true"></i>  
                                <a href="/personal-details"> Personal Details</a>
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                                <i className="fa fa-address-book" aria-hidden="true"></i> 
                                <a href="/business-details"> Business Details</a>
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                                <i className="fa fa-lock" aria-hidden="true"></i> 
                                <a href="/change-password"> Change Password</a>
                            </ListGroup.Item>  
                        </ListGroup>                
                    </Col>
                    <Col md={9}>
                        <Card className="mb-5" style={{border: "none"}}>
                            <Card.Header style={{border: "none"}}>
                                <h2 className="text-success">
                                    Personal Details {"\u00a0\u00a0"} <a href="javascript:void(0);" onClick={handleEdit}><i className="fa fa-pencil"></i></a>
                                    <a href="/" className="btn btn-secondary float-right ml-2">
                                        <i className="fa fa-chevron-left"></i>  Back to Dashboard
                                    </a>
                                    {
                                        (readOnly === false) ? 
                                            <Button variant="primary" className="float-right ml-2" onClick={handleUpdate}>
                                                <i className="fa fa-floppy-o"></i> Save Changes
                                            </Button>
                                        :
                                            ""
                                    }
                                </h2>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{t("lable.first_name")} <span className="text-danger">*</span></Form.Label>
                                                <Form.Control type="text" 
                                                    name="first_name"
                                                    value={userData ? userData.first_name : ""}
                                                    onChange={handleChange}
                                                    readOnly={readOnly}
                                                />
                                                <Form.Text className="text-danger">{(!errors) ? "" : errors.first_name}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{t("lable.middle")}</Form.Label>
                                                <Form.Control type="text" 
                                                    name="middle_name"
                                                    value={userData ? userData.middle_name : ""}
                                                    onChange={handleChange}
                                                    readOnly={readOnly}
                                                />
                                                <Form.Text className="text-danger">{(!errors) ? "" : errors.middle_name}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{t("lable.last_name")} <span className="text-danger">*</span></Form.Label>
                                                <Form.Control type="text" 
                                                    name="last_name"
                                                    value={userData ? userData.last_name : ""}
                                                    onChange={handleChange}
                                                    readOnly={readOnly}
                                                />
                                                <Form.Text className="text-danger">{(!errors) ? "" : errors.last_name}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>{t("lable.mobile_number")} <span className="text-danger">*</span></Form.Label>
                                                <Form.Control type="tel" 
                                                    name="mobile_number"
                                                    value={userData ? userData.mobile_number : ""}
                                                    onChange={handleChange}
                                                    maxLength="10"
                                                    pattern="[0-9]*"
                                                    readOnly={readOnly}
                                                />
                                                <Form.Text className="text-danger">{(!errors) ? "" : errors.mobile_number}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>{t("lable.alternate_contact_number")}</Form.Label>
                                                <Form.Control type="tel" 
                                                    name="alternate_mobile_number"
                                                    value={userData ? userData.alternate_mobile_number : ""}
                                                    onChange={handleChange}
                                                    maxLength="10"
                                                    readOnly={readOnly}
                                                />
                                                <Form.Text className="text-danger">{(!errors) ? "" : errors.alternate_mobile_number}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col md={12}>
                                            <Form.Group>
                                                <Form.Label>{t("lable.email")}  <span className="text-danger">*</span></Form.Label>
                                                <Form.Control type="email" 
                                                    name="email"
                                                    value={userData ? userData.email : ""}
                                                    onChange={handleChange}
                                                    readOnly={readOnly}
                                                />
                                                <Form.Text className="text-danger">{(!errors) ? "" : errors.email}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col md={12}>
                                            <Form.Group>
                                                <Form.Label>{t("lable.address")}</Form.Label>
                                                <Form.Control as="textarea" rows="5" 
                                                    name="address"
                                                    value={userData ? userData.address : ""}
                                                    onChange={handleChange}
                                                    readOnly={readOnly}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Loader show={loading}/>
        </>
    )
}

export default PersonalDetails;