import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Form, InputGroup, Badge, Alert } from "react-bootstrap";
import  * as Constants from "../../config/Constants";
import { UserContext } from "../context/user.context";

import sos_logo from "../../assets/image/sos-logo.png";
import "./authenticate.scss";

function Login(props) {
    const isEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const { setLocalStorage, removeLocalStorage } = React.useContext(UserContext);
    const [errors, setErrors] = useState(null);    
    const [fields, setFields] = useState({
        email: "",
        password: ""
    });
    const [passwordShow, setPasswordShow] = useState({
        hideShow: "hide",
        type: "password"
    });

    useEffect(() => {
        removeLocalStorage();
    }, []);

    function handleValidation() {
        let field = fields;
        let errors = {};
        let formIsValid = true;

        if (field["email"] === "") {
            formIsValid = false;
            errors["email"] = "Please enter the Email!";
        } else if (!isEmail.test(fields["email"])) {
            formIsValid = false;
            errors["email"] = "Email is not valid!";
        }
        if (field["password"] === "") {
            formIsValid = false;
            errors["password"] = "Please enter the Password!";
        }

        setErrors((!formIsValid) ? errors : null);
        return formIsValid;
   }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFields({...fields, [name]: value});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            const formData = fields;
            fetch(Constants.API_LOGIN, { 
                method: "POST", 
                body: JSON.stringify(formData), 
                headers: { 
                    "Content-type": "application/json; charset=UTF-8"
                }
            }) 
            .then(response => response.json()) 
            .then(
                data => {
                    if (data.status) {
                        setLocalStorage(data.token, data.userData);
                        props.history.push("/");
                    } else {
                        setErrors({server:data.message});
                    }
                }
            ); 
        }
    }

    const handleHideShowPassword = (value, e) => {
        setPasswordShow({
            hideShow: value,
            type: (value === "show") ? "text" : "password"
        })
    }

    return (
        <Container fluid className="container-login100">
            <Row className="row">
                <Col md={6} className="login100-pic">
                    <a href={Constants.WEBSITE_BASE_URL}><Image src={sos_logo} alt="sos logo" style={{width: "100%"}} /></a>
                </Col>
                <Col md={6} className="bl">
                    { 
                        (errors) ?
                            <Alert variant="danger">
                                {errors.server}
                                {errors.email}
                                <br/>
                                {errors.password}
                            </Alert>
                        : ""
                    }
                    <Form>
                        <Form.Row className="mb-4">
                            <Form.Label srOnly> Username </Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    <InputGroup.Text><i className="fa fa-envelope" aria-hidden="true" style={{fontSize: "20px"}}></i></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control type="email" 
                                    name="email"
                                    onChange={handleChange} 
                                    placeholder="Email" />
                            </InputGroup>
                        </Form.Row>
                        <Form.Row className="mb-4">
                            <Form.Label srOnly> Password </Form.Label>
                            <InputGroup className="mb-2">
                                <InputGroup.Prepend>
                                    {
                                        (passwordShow.hideShow === "hide") ?
                                            <InputGroup.Text><i className="fa fa-lock" onClick={(e) => handleHideShowPassword('show', e)}></i></InputGroup.Text>
                                        :
                                            <InputGroup.Text><i className="fa fa-unlock-alt" onClick={(e) => handleHideShowPassword('hide', e)}></i></InputGroup.Text>
                                    }
                                </InputGroup.Prepend>
                                <Form.Control type={passwordShow.type}
                                    name="password"
                                    onChange={handleChange}
                                    placeholder="Password" />
                            </InputGroup>
                        </Form.Row>
                        <div className="container-login100-form-btn mb-3">
                            <button className="login100-form-btn" onClick={handleSubmit}>
                                Login
                            </button>
                        </div>
                        <div className="container-login100-form-btn mb-3">
                            <a className="login100-form-lable" href="/register">Register</a>
                        </div>
                        <div className="text-center p-t-12 text-wheth">
                            <Badge className="txt1">
                                Forgot Username / Password?
                            </Badge>
                            <Link to="forgot-password" className="txt2"> Click Here</Link>
                        </div>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default Login;