import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  ListGroup,
  Card,
  Button,
} from "react-bootstrap";
import Dialog from "react-bootstrap-dialog";
import { AlertContext } from "../context/Alert";
import * as Constants from "../../config/Constants";
import { UserContext } from "../context/user.context";
import Loader from "../header/Loader";
import profile_default from "../../assets/image/profile_default.png";
import company_logo from "../../assets/image/company_logo.png";
import { LocalizationContext } from "../context/localization/localization.context";

import "./profile.scss";

function BusinessDetails() {
  const { token, userId, userData, setLocalStorage } = React.useContext(
    UserContext
  );
  const { t } = React.useContext(LocalizationContext);
  const isPan = /^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/;
  const isEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const isNumber = /^[0-9\b]+$/;
  const { dialog } = React.useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [pan, setPan] = useState("");
  const [companyData, setcompanyData] = useState(null);
  const [errors, setErrors] = useState(null);
  const [readOnly, setReadOnly] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetch(Constants.API_COMPANY_BY_USER_ID + userId, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.status) {
          //   console.log(data.companyData);
          setcompanyData(data.companyData);
          const panNo = data.companyData ? data.companyData.pan_number : "";
          setPan(panNo);
        }
      });
  }, []);

  function handleChange(e) {
    if (e.target.name === "phone") {
      const onlyNums = e.target.value.replace(/[^0-9]/g, "");
      const number = onlyNums.replace(/(\d{3})(\d{3})(\d{4})/, "$1$2$3");
      setcompanyData({ ...companyData, ["phone"]: number });
    } else {
      const { name, value } = e.target;
      setcompanyData({ ...companyData, [name]: value });
    }

    let errors = {};
    if (e.target.value !== "" && e.target.name === "gst_number") {
      if (e.target.value.length < 15) {
        errors["gst_number"] = t("errors.gst_must_be_15_char");
      }
      const pan_number = e.target.value.substring(2, 12);
      setPan(pan_number);
    }
    setErrors(errors);
  }

  function handleChangeFile(e) {
    setLoading(true);
    const formData = new FormData();
    const url = Constants.API_UPLOAD_COMPANY_LOGO + companyData.company_id;
    formData.append("company_logo", e.target.files[0]);
    formData.append("user_id", userId);
    fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        dialog.show({
          body: data.message,
          actions: [
            Dialog.OKAction(() => {
              if (data.status) {
                setLocalStorage(data.token, data.userData);
                window.location.reload(false);
              }
            }),
          ],
          bsSize: "small",
        });
      });
  }

  function handleValidation(e) {
    let fields = companyData;
    let errors = {};
    let formIsValid = true;
    if (fields !== null) {
      if (fields["company_name"] === "") {
        formIsValid = false;
        errors["company_name"] = t("errors.company_name");
      }
      if (!fields["gst_number"] || fields["gst_number"] === "") {
        formIsValid = false;
        errors["gst_number"] = t("errors.gst");
      } else if (fields["gst_number"].length < 15) {
        formIsValid = false;
        errors["gst_number"] = t("errors.gst_must_be_15_char");
      }
      if (pan === "") {
        formIsValid = false;
        errors["pan_number"] = t("errors.pan");
      } else if (!isPan.test(pan)) {
        formIsValid = false;
        errors["pan_number"] = "Invalid pan number";
      }
      if (fields["phone"] === "") {
        formIsValid = false;
        errors["phone"] = t("errors.phone");
      } else if (!isNumber.test(fields["phone"])) {
        formIsValid = false;
        errors["phone"] = t("errors.only_number");
      }
      if (fields["email"] === "") {
        formIsValid = false;
        errors["email"] = t("errors.email");
      } else if (!isEmail.test(fields["email"])) {
        formIsValid = false;
        errors["email"] = t("errors.valid_email");
      }
    } else {
      formIsValid = false;
      errors["company_name"] = t("errors.company_name");
      errors["gst_number"] = t("errors.gst");
      errors["pan_number"] = t("errors.pan");
      errors["phone"] = t("errors.phone");
      errors["email"] = t("errors.email");
    }
    setErrors(errors);
    return formIsValid;
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (handleValidation()) {
      setLoading(true);
      let url = Constants.API_COMPANY;
      let method = "POST";
      if (companyData.company_id) {
        url = Constants.API_COMPANY + companyData.company_id;
        method = "PUT";
      }
      const formData = {
        user_id: userId,
        company_name: companyData.company_name,
        phone: companyData.phone,
        email: companyData.email,
        gst_number: companyData.gst_number,
        pan_number: pan,
        address: companyData.address,
      };
      fetch(url, {
        method: method,
        body: JSON.stringify(formData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          dialog.show({
            body: data.message,
            actions: [
              Dialog.OKAction(() => {
                if (data.status) {
                  setLocalStorage(data.token, data.userData);
                  window.location.reload(false);
                }
              }),
            ],
            bsSize: "small",
          });
        });
    }
  }

  const handleEdit = () => {
    setReadOnly(false);
  };

  return (
    <>
      <Container className="mt-cust">
        <Row>
          <Col md="3">
            <div className="text-center">
              {userData.profile_image !== null &&
              userData.profile_image !== "" ? (
                <Image
                  src={Constants.API_BASE_URL + userData.profile_image}
                  thumbnail
                />
              ) : (
                <Image src={profile_default} thumbnail />
              )}
              <h6 className="mt-4">
                {userData ? (
                  <>
                    {userData.first_name + " " + userData.last_name}
                    <br />
                    <br />
                    <strong>User Login</strong>
                    <p className="mt-2">{userData.email}</p>
                  </>
                ) : (
                  ""
                )}
              </h6>
            </div>
            <br />
            <ListGroup as="ul" style={{ marginBottom: "5rem" }}>
              <ListGroup.Item as="li">
                <i className="fa fa-address-book" aria-hidden="true"></i>
                <a href="/personal-details"> Personal Details</a>
              </ListGroup.Item>
              <ListGroup.Item as="li" className="div-active">
                <i className="fa fa-address-book" aria-hidden="true"></i>
                <a href="/business-details"> Business Details</a>
              </ListGroup.Item>
              <ListGroup.Item as="li">
                <i className="fa fa-lock" aria-hidden="true"></i>
                <a href="/change-password"> Change Password</a>
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={9}>
            <Card className="mb-5" style={{ border: "none" }}>
              <Card.Header style={{ border: "none" }}>
                <h2 className="text-success">
                  Business Details {"\u00a0\u00a0"}{" "}
                  <a href="javascript:void(0);" onClick={handleEdit}>
                    <i className="fa fa-pencil"></i>
                  </a>
                  <a href="/" className="btn btn-secondary float-right ml-2">
                    <i className="fa fa-chevron-left"></i> Back to Dashboard
                  </a>
                  {readOnly === false ? (
                    <Button
                      variant="primary"
                      className="float-right ml-2"
                      onClick={handleSubmit}
                    >
                      <i className="fa fa-floppy-o"></i> Save Changes
                    </Button>
                  ) : (
                    ""
                  )}
                </h2>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col md={9}>
                      <Form.Row>
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label>
                              {t("lable.company_name")}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="company_name"
                              value={
                                companyData ? companyData.company_name : ""
                              }
                              onChange={handleChange}
                              readOnly={readOnly}
                            />
                            <Form.Text className="text-danger">
                              {!errors ? "" : errors.company_name}
                            </Form.Text>
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label>
                              {t("lable.email")}{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="email"
                              name="email"
                              value={companyData ? companyData.email : ""}
                              onChange={handleChange}
                              readOnly={readOnly}
                            />
                            <Form.Text className="text-danger">
                              {!errors ? "" : errors.email}
                            </Form.Text>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>
                              {t("lable.gst_number")}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="gst_number"
                              value={companyData ? companyData.gst_number : ""}
                              onChange={handleChange}
                              maxLength="15"
                              style={{ textTransform: "uppercase" }}
                              readOnly={readOnly}
                            />
                            <Form.Text className="text-danger">
                              {!errors ? "" : errors.gst_number}
                            </Form.Text>
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group>
                            <Form.Label>
                              {t("lable.pan_number")}{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="pan_number"
                              value={pan}
                              maxLength="10"
                              style={{ textTransform: "uppercase" }}
                              readOnly="true"
                            />
                            <Form.Text className="text-danger">
                              {!errors ? "" : errors.pan_number}
                            </Form.Text>
                          </Form.Group>
                        </Col>
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label>
                              {t("lable.company_address")}{" "}
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              rows="5"
                              name="address"
                              value={companyData ? companyData.address : ""}
                              onChange={handleChange}
                              readOnly={readOnly}
                            />
                          </Form.Group>
                        </Col>
                      </Form.Row>
                    </Col>
                    <Col md={3}>
                      <Form.Row>
                        <Col md={12}>
                          <Form.Group>
                            <Form.Label>
                              {t("lable.company_phone")}{" "}
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Form.Control
                              type="tel"
                              name="phone"
                              value={companyData ? companyData.phone : ""}
                              onChange={handleChange}
                              maxLength="10"
                              readOnly={readOnly}
                            />
                            <Form.Text className="text-danger">
                              {!errors ? "" : errors.phone}
                            </Form.Text>
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <div className="text-center mt-3 company-log">
                        {companyData &&
                        companyData.company_logo !== null &&
                        companyData.company_logo !== "" ? (
                          <Image
                            src={
                              Constants.API_BASE_URL + companyData.company_logo
                            }
                            thumbnail
                          />
                        ) : (
                          <Image src={company_logo} thumbnail />
                        )}
                        <h6 className="mt-2 mb-2">
                          {t("lable.company_logo_upload")}
                        </h6>
                        {companyData ? (
                          <Form.Group className="text-left">
                            <Form.File
                              label={t("lable.choose_photo")}
                              custom
                              onChange={handleChangeFile}
                            />
                          </Form.Group>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Loader show={loading} />
    </>
  );
}

export default BusinessDetails;
