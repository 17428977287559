import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useHistory, Link } from "react-router-dom";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import Moment from "moment";
import DataTable from "react-data-table-component";
import Dialog from "react-bootstrap-dialog";
import { AlertContext } from "../context/Alert";
import * as Constants from "../../config/Constants";
import { UserContext } from "../context/user.context";
import Loader from "../header/Loader";
import StatusProgressBar from "./StatusProgressBar";
import {
  getProjectStatusArr,
  getProjectSubStatusArr,
  getProjectPaymentSubStatusArr,
} from "../../config/config";

import company_logo from "../../assets/image/company_logo.png";

import "./project.scss";

function ProjectList(props) {
  const { token, userData, userId } = React.useContext(UserContext);
  const { dialog } = React.useContext(AlertContext);
  let history = useHistory();
  const statusArr = getProjectStatusArr();
  const subStatusArr = getProjectPaymentSubStatusArr();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [statusProgressBarShow, setStatusProgressBarShow] = useState("hide");
  const [projectId, setProjectId] = useState();
  const [projectSubStatus, setProjectSubStatus] = useState("");
  const [projectStatus, setProjectStatus] = useState({
    project_code: "",
    project_name: "",
    status: "",
    sub_status: "",
  });

  function fetchProjects(page, size = perPage) {
    setLoading(true);
    fetch(
      Constants.API_GET_PROJECT_BY_COMPANY +
        userData["company_id"] +
        "?page=" +
        page +
        "&per_page=" +
        size,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    )
      .then((response) => response.json())
      .then((resData) => {
        setLoading(false);
        setTableData(resData.projectData.data);
        setTotalRows(resData.projectData.total);
      });
  }

  const addProject = () => {
    if (
      userData &&
      userData.company_id !== null &&
      userData.company_id !== ""
    ) {
      props.history.push("/add-project");
    } else {
      dialog.show({
        body: "Complete your Business Profile first",
        actions: [
          Dialog.OKAction(() => {
            props.history.push("/business-details");
          }),
        ],
        bsSize: "small",
      });
    }
  };

  useEffect(() => {
    fetchProjects(1);
  }, []);

  const handleDelete = useCallback((row) => async () => {
    dialog.show({
      body: `Are you sure you want to delete:\r${row.project_name}?`,
      bsSize: "small",
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction(() => {
          setLoading(true);
          fetch(Constants.API_PROJECT + row.project_id, {
            method: "DELETE",
            headers: {
              Authorization: "Bearer " + token,
            },
          })
            .then((response) => response.json())
            .then((data) => {
              setLoading(false);
              dialog.show({
                body: data.message,
                bsSize: "small",
                actions: [
                  Dialog.OKAction(() => {
                    fetchProjects(1);
                  }),
                ],
              });
            });
        }),
      ],
    });
  });

  const handleEdit = useCallback((row) => async () => {
    history.push(`/add-project?project_id=${row.project_id}`);
  });

  const columns = useMemo(() => [
    {
      cell: (row) => (
        <Button onClick={handleEdit(row)}>
          <i className="fa fa-pencil"></i>
        </Button>
      ),
      width: "65px",
    },
    {
      name: "Project Code",
      selector: "project_id",
      sortable: true,
      cell: (row) => (
        <div>
          <Link
            className="text-primary"
            to={`project-details/${row.project_id}`}
          >
            {row.project_code}
          </Link>
        </div>
      ),
      width: "150px",
    },
    {
      name: "Project Name",
      selector: "project_name",
      sortable: true,
    },
    {
      name: "Files Submitted",
      cell: (row) => <div>--</div>,
      width: "160px",
    },
    {
      name: "Created Date",
      selector: "created_ts",
      sortable: true,
      cell: (row) => (
        <div>{Moment(row.created_ts).format("DD-MM-YYYY h:mm A")}</div>
      ),
      width: "180px",
    },
    {
      name: "Current Status",
      cell: (row) => (
        <div className="w-100">
          {(() => {
            let sub_status =
              row.project_sub_status && row.project_sub_status !== ""
                ? row.project_sub_status
                : "";

            let cls =
              sub_status === ""
                ? ""
                : "mt-2 p-2 text-center rounded text-white w-100 bg-" +
                  subStatusArr[sub_status]["color"];

            if (row.project_status === "1") {
              return (
                <label className=" mt-2 p-2 text-center rounded bg-primary text-white w-100">
                  New
                </label>
              );
            } else if (row.project_status === "2" && sub_status === "") {
              return (
                <label className=" mt-2 p-2 text-center rounded bg-info text-white w-100">
                  {statusArr[2]["status"]}
                </label>
              );
            } else if (row.project_status === "2" && sub_status !== "") {
              return (
                <label className={cls}>
                  {statusArr[3]["status"]}{" "}
                  <small>({subStatusArr[sub_status]["display_name"]})</small>
                </label>
              );
            } else if (row.project_status === "3" && sub_status === "") {
              return (
                <label className=" mt-2 p-2 text-center rounded bg-secondary text-white w-100">
                  {statusArr[3]["status"]}
                </label>
              );
            } else if (row.project_status === "3" && sub_status !== "") {
              return (
                <label className={cls}>
                  {statusArr[4]["status"]}{" "}
                  <small>({subStatusArr[sub_status]["display_name"]})</small>
                </label>
              );
            } else if (row.project_status === "4" && sub_status === "") {
              return (
                <label className=" mt-2 p-2 text-center rounded bg-info text-white w-100">
                  {statusArr[4]["status"]} Done
                </label>
              );
            } else if (row.project_status === "4" && sub_status !== "") {
              return (
                <label className={cls}>
                  {statusArr[5]["status"]}{" "}
                  <small>({subStatusArr[sub_status]["display_name"]})</small>
                </label>
              );
            } else if (row.project_status === "5" && sub_status === "") {
              return (
                <label className=" mt-2 p-2 text-center rounded bg-success text-white w-100">
                  {statusArr[5]["status"]}
                </label>
              );
            }
          })()}
        </div>
      ),
      width: "220px",
    },
    {
      name: "Action",
      cell: (row) => (
        <button className="btn btn-danger" onClick={handleDelete(row)}>
          <i className="fa fa-trash"></i>
        </button>
      ),
      width: "100px",
    },
  ]);

  const handlePageChange = (page) => {
    fetchProjects(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchProjects(page, newPerPage);
    setPerPage(newPerPage);
  };

  const handleRowClicked = (row, e) => {
    console.log(e.target);
    setStatusProgressBarShow("show");
    setProjectId(row.project_id);
    setProjectStatus({
      project_code: row.project_code,
      project_name: row.project_name,
      status: row.project_status,
      sub_status: row.project_sub_status,
    });
  };

  return (
    <>
      <Container className="mt-cust bread-crumb">
        <Row>
          <Col md={3} className="mb-3">
            {userData &&
            userData.company_logo !== null &&
            userData.company_logo !== "" ? (
              <Image
                src={Constants.API_BASE_URL + userData.company_logo}
                width="90"
                height="90"
              ></Image>
            ) : (
              <Image src={company_logo} width="90" height="90"></Image>
            )}
          </Col>
          <Col md={9} className="mb-3">
            <h2 className="text-center">
              View Project Details
              <a href="/" className="btn btn-default float-right">
                <i className="fa fa-chevron-left"></i> Back to Dashboard
              </a>
              <a
                onClick={(e) => addProject()}
                className="btn btn-primary float-right mr-1"
              >
                <i className="fa fa-plus"></i> Add Project
              </a>
            </h2>
          </Col>
        </Row>
      </Container>
      {statusProgressBarShow === "show" ? (
        <StatusProgressBar
          projectId={projectId}
          projectStatus={projectStatus}
        />
      ) : (
        ""
      )}
      <Container className="form-container">
        <Row>
          <Col md={12}>
            <DataTable
              columns={columns}
              data={tableData}
              className="pr_datatable"
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              noHeader={true}
              onRowClicked={handleRowClicked}
              pointerOnHover
              highlightOnHover
            />
          </Col>
        </Row>
      </Container>
      <Loader show={loading} />
    </>
  );
}

export default ProjectList;
