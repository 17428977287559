import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Form,
  Table,
} from "react-bootstrap";
import Moment from "moment";
import Dialog from "react-bootstrap-dialog";
import { AlertContext } from "../context/Alert";
import Loader from "../header/Loader";
import * as Constants from "../../config/Constants";
// import { getToken, getUser } from "../../helpers/Session";
import { UserContext } from "../context/user.context";
import company_logo from "../../assets/image/company_logo.png";
import { LocalizationContext } from "../context/localization/localization.context";

function AddProject(props) {
  let textInput = null;
  let textInputDate = null;
  let textInputCheque = null;
  let textInputName = null;
  let textInputBank = null;
  let textInputBranch = null;
  const { token, userData } = React.useContext(UserContext);
  const { t } = React.useContext(LocalizationContext);
  const { dialog } = React.useContext(AlertContext);
  let { projectId } = useParams();
  // const userData= getUser();
  // const token = getToken();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [quotationData, setQuotationData] = useState(null);
  const [paymentData, setPaymentData] = useState({
    payment_date: "",
    payment_mode: "Cash",
    cheque_no: "",
    account_holder_name: "",
    bank_name: "",
    bank_branch: "",
    neft_reference_number: "",
    amount: "",
  });

  useEffect(() => {
    fetchApprovedQuotationData(projectId);
  }, []);

  const fetchApprovedQuotationData = (projectId) => {
    setLoading(true);
    fetch(Constants.API_GET_APPROVED_QUOTATION + projectId, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setQuotationData(data.quotationData);
        setPaymentData({
          ...paymentData,
          ["amount"]: data.quotationData.amount,
        });
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPaymentData({ ...paymentData, [name]: value });
  };

  function handleValidation() {
    let fields = paymentData;
    let errors = {};
    let formIsValid = true;

    if (fields["payment_date"] === "") {
      formIsValid = false;
      textInputDate.focus();
      errors["payment_date"] = t("errors.payment_date");
    }
    if (fields["payment_mode"] === "") {
      formIsValid = false;
      errors["payment_mode"] = t("errors.payment_mode");
    }
    if (fields["payment_mode"] === "Cheque") {
      if (fields["cheque_no"] === "") {
        textInputCheque.focus();
        formIsValid = false;
        errors["cheque_no"] = t("errors.cheque_no");
      }
      if (fields["account_holder_name"] === "") {
        textInputName.focus();
        formIsValid = false;
        errors["account_holder_name"] = t("errors.account_holder_name");
      }
      if (fields["bank_name"] === "") {
        textInputBank.focus();
        formIsValid = false;
        errors["bank_name"] = t("errors.bank_name");
      }
      if (fields["bank_branch"] === "") {
        textInputBranch.focus();
        formIsValid = false;
        errors["bank_branch"] = t("errors.bank_branch");
      }
    }
    if (fields["payment_mode"] === "NEFT") {
      if (fields["neft_reference_number"] === "") {
        formIsValid = false;
        errors["neft_reference_number"] = t("errors.neft_reference_number");
      }
    }
    if (fields["amount"] === "") {
      formIsValid = false;
      errors["amount"] = t("errors.amount");
    }

    setErrors(errors);
    return formIsValid;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation()) {
      setLoading(true);
      fetch(Constants.API_PAYMENT + quotationData.quotation_id, {
        method: "PUT",
        body: JSON.stringify(paymentData),
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          dialog.show({
            body: data.message,
            actions: [
              Dialog.OKAction(() => {
                if (data.status) {
                  props.history.push(`/project-details/${projectId}`);
                }
              }),
            ],
            bsSize: "small",
          });
        });
    }
  };

  return (
    <>
      <Container className="mt-cust bread-crumb">
        <Row>
          <Col md={12}>
            <Row>
              <Col md={3} className="mb-3">
                {userData &&
                userData.company_logo !== null &&
                userData.company_logo !== "" ? (
                  <Image
                    src={Constants.API_BASE_URL + userData.company_logo}
                    width="90"
                    height="90"
                  ></Image>
                ) : (
                  <Image src={company_logo} width="90" height="90"></Image>
                )}
              </Col>
              <Col md={6} className="mb-3 text-center">
                <h2>Payment</h2>
              </Col>
              <Col md={3} className="mb-3">
                <a href="/" className="btn btn-default float-right">
                  <i className="fa fa-chevron-left"></i> Back to Project Details
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Container style={{ marginTop: "30px", marginBottom: "5rem" }}>
        <Card className="mb-3">
          <Card.Header>
            <Card.Text>Quotation Details</Card.Text>
          </Card.Header>
          <Card.Body>
            <Table responsive>
              <tbody>
                <tr>
                  <th style={{ borderTop: "0px" }}>Project Name : </th>
                  <td style={{ borderTop: "0px" }}>
                    {quotationData ? quotationData.project_name : " -- "}
                  </td>
                  <th style={{ borderTop: "0px" }}>Project Code : </th>
                  <td style={{ borderTop: "0px" }}>
                    {quotationData ? quotationData.project_code : " -- "}
                  </td>
                </tr>
                <tr>
                  <th>Revision Number</th>
                  <td>
                    {quotationData ? quotationData.quotation_revision : " -- "}
                  </td>
                  <th>Quotation Approval Date</th>
                  <td>
                    {quotationData
                      ? Moment(quotationData.modify_ts).format(
                          "DD-MM-YYYY h:mm A"
                        )
                      : " -- "}
                  </td>
                </tr>
                <tr>
                  <th>Amount</th>
                  <td>
                    <i className="fa fa-rupee"></i>{" "}
                    {quotationData
                      ? parseFloat(quotationData.amount).toFixed(2)
                      : " -- "}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card.Body>
          <Card.Header className="text-center border-top">
            <Card.Text>Payment</Card.Text>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      {t("lable.payment_date")}{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      ref={(input) => {
                        textInputDate = input;
                      }}
                      name="payment_date"
                      onChange={handleChange}
                      max={Moment().format("YYYY-MM-DD")}
                    />
                    <Form.Text className="text-danger">
                      {!errors ? "" : errors.payment_date}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>
                      {t("lable.amount")} <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      readOnly
                      value={
                        paymentData !== ""
                          ? parseFloat(paymentData.amount).toFixed(2)
                          : ""
                      }
                      name="amount"
                      onChange={handleChange}
                    />
                    <Form.Text className="text-danger">
                      {!errors ? "" : errors.amount}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>
                      {t("lable.payment_mode")}{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      name="payment_mode"
                      as="select"
                      onChange={handleChange}
                    >
                      <option value="Cash">Cash</option>
                      <option value="Cheque">Cheque</option>
                      <option value="NEFT">NEFT</option>
                    </Form.Control>
                    <Form.Text className="text-danger">
                      {!errors ? "" : errors.payment_mode}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col
                  md={6}
                  style={{
                    display: `${
                      paymentData.payment_mode === "Cheque" ? "block" : "none"
                    }`,
                  }}
                >
                  <Form.Group>
                    <Form.Label>
                      {t("lable.cheque_no")}{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      ref={(input) => {
                        textInputCheque = input;
                      }}
                      name="cheque_no"
                      onChange={handleChange}
                    />
                    <Form.Text className="text-danger">
                      {!errors ? "" : errors.cheque_no}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col
                  md={6}
                  style={{
                    display: `${
                      paymentData.payment_mode === "Cheque" ? "block" : "none"
                    }`,
                  }}
                >
                  <Form.Group>
                    <Form.Label>
                      {t("lable.account_holder_name")}{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      ref={(input) => {
                        textInputName = input;
                      }}
                      name="account_holder_name"
                      onChange={handleChange}
                      style={{ textTransform: "capitalize" }}
                    />
                    <Form.Text className="text-danger">
                      {!errors ? "" : errors.account_holder_name}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col
                  md={6}
                  style={{
                    display: `${
                      paymentData.payment_mode === "Cheque" ? "block" : "none"
                    }`,
                  }}
                >
                  <Form.Group>
                    <Form.Label>
                      {t("lable.bank_name")}{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      ref={(input) => {
                        textInputBank = input;
                      }}
                      name="bank_name"
                      onChange={handleChange}
                      style={{ textTransform: "capitalize" }}
                    />
                    <Form.Text className="text-danger">
                      {!errors ? "" : errors.bank_name}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col
                  md={6}
                  style={{
                    display: `${
                      paymentData.payment_mode === "Cheque" ? "block" : "none"
                    }`,
                  }}
                >
                  <Form.Group>
                    <Form.Label>
                      {t("lable.bank_branch")}{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      ref={(input) => {
                        textInputBranch = input;
                      }}
                      name="bank_branch"
                      onChange={handleChange}
                      style={{ textTransform: "capitalize" }}
                    />
                    <Form.Text className="text-danger">
                      {!errors ? "" : errors.bank_branch}
                    </Form.Text>
                  </Form.Group>
                </Col>
                <Col
                  md={6}
                  style={{
                    display: `${
                      paymentData.payment_mode === "NEFT" ? "block" : "none"
                    }`,
                  }}
                >
                  <Form.Group>
                    <Form.Label>
                      {t("lable.neft_reference_number")}{" "}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="neft_reference_number"
                      onChange={handleChange}
                    />
                    <Form.Text className="text-danger">
                      {!errors ? "" : errors.neft_reference_number}
                    </Form.Text>
                  </Form.Group>
                </Col>
              </Form.Row>
              <Row>
                <Col md={12} className="text-center mt-5">
                  <Button className="btn-default" onClick={handleSubmit}>
                    <i class="fa fa-arrow-circle-left" aria-hidden="true"></i>{" "}
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card.Body>
        </Card>
      </Container>
      <Loader show={loading} />
    </>
  );
}

export default AddProject;
