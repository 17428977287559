import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Link, useParams } from "react-router-dom";
import { Row, Col, Card, Form, Button } from "react-bootstrap";
import DataTable from "react-data-table-component";
import Moment from 'moment';
import Dialog from "react-bootstrap-dialog";
import { AlertContext } from "../context/Alert";
import Loader from '../header/Loader';
import  * as Constants from "../../config/Constants";
import { UserContext } from "../context/user.context";
import { LocalizationContext } from "../context/localization/localization.context";

function File(props) {
    const { token } = React.useContext(UserContext);
    const { t } = React.useContext(LocalizationContext);
    const { dialog } = React.useContext(AlertContext);
    let { projectId } = useParams();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);    
    const [tableData, setTableData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [fileLable, setFileLable] = useState("Choose file");
    const [fileData, setFileData] = useState({
        project_id: projectId,
        file_name: "",
        comments: "",
        upload_file: ""
    });

    useEffect(() => {
        if (props.fileLoad) {
            fetchFiles(1);
        }
    }, [props.fileLoad]);

    const fetchFiles = (page, size = perPage) => {
        setLoading(true);
        fetch(Constants.API_GET_FILES_BY_PROJECT + projectId + "?page=" + page + "&per_page=" + size , { 
            method: "GET",
            headers: { 
                "Authorization": "Bearer " + token
            }
        }) 
        .then(response => response.json())
        .then(resData => {
                setLoading(false);
                setTableData(resData.fileData.data);
                setTotalRows(resData.fileData.total);
            }
        );
    };

    const handleDelete = useCallback (
        row => async () => {
            dialog.show({
                body: `Are you sure you want to delete:\r${row.file_name}?`,
                bsSize: 'small',
                actions: [
                    Dialog.CancelAction(),
                    Dialog.OKAction(() => {
                        setLoading(true);
                        fetch(Constants.API_FILE + row.file_id, { 
                            method: "DELETE",
                            headers: { 
                                "Authorization": "Bearer " + token
                            }
                        }) 
                        .then(response => response.json())
                        .then(
                            data => {
                                setLoading(false);
                                dialog.show({
                                    body: data.message,
                                    bsSize: 'small',
                                    actions: [
                                        Dialog.OKAction(() => {
                                            fetchFiles(1);
                                        })
                                    ],
                                });
                            }
                        );
                    })
                ]
            });
        }
    );

    const columns = useMemo(
        () => [
            {
                name: "File Code",
                selector: "file_id",
                sortable: true,
                cell: row => <div>{row.file_code}</div>,
                width: '150px',
            }, {
                name: "File Name/Description",
                selector: "file_name",
                sortable: true,
                wrap: true,
            }, {
                name: "File Commentes",
                selector: "comments",
                sortable: true,
                wrap: true,
            }, {
                name: "Created Date",
                selector: "created_ts",
                sortable: true,
                cell: row => <div>{Moment(row.created_ts).format('DD-MM-YYYY h:mm A')}</div>,
                width: '200px',
            }, {
                name: "Download File",
                cell: row => <div className="text-center w-100"><a className="btn btn-success" href={Constants.API_BASE_URL + row.upload_file} target="_blank" download><i className="fa fa-download"></i></a></div>,
                width: '150px',
            }, {
                name: "Delete",
                cell: row => <div className="text-center w-100"><button className="btn btn-danger" onClick={handleDelete(row)}><i className="fa fa-trash"></i></button></div>,
                width: '100px',
            }
        ]
    );

    const handlePageChange = page => {
        fetchFiles(page);
        setCurrentPage(page);
    };
    
    const handlePerRowsChange = async (newPerPage, page) => {
        fetchFiles(page, newPerPage);
        setPerPage(newPerPage);
    };
    
    const handleChange = (e) => {
        if (e.target.name == 'upload_file') { 
            const file = e.target.files[0];
            setFileData({ ...fileData, ['upload_file']: e.target.files[0]});
            setFileLable(file.name);
        } else {
            const { name, value } = e.target;
            setFileData({ ...fileData, [name]: value});
        }
    }

    const handleValidation = () => {
        let fields = fileData;
        let errors = {};
        let formIsValid = true;

        if (fields["file_name"] === "") {
            formIsValid = false;
            errors["file_name"] = t("errors.file_name");
        }
        if (fields["comments"] === "") {
            formIsValid = false;
            errors["comments"] = t("errors.comments");
        }
        if (fields["upload_file"] === "") {
            formIsValid = false;
            errors["upload_file"] = t("errors.upload_file");
        }

        setErrors(errors);
        return formIsValid;
    }

    const handleSubmit = () => {
        if (handleValidation()) {
            setLoading(true);
            const formData = new FormData();
            formData.append("project_id", fileData.project_id);
            formData.append("file_name", fileData.file_name);
            formData.append("comments", fileData.comments);
            formData.append("upload_file", fileData.upload_file);

            fetch(Constants.API_FILE, { 
                method: "POST", 
                body: formData, 
                headers: { 
                    "Authorization": "Bearer " + token
                }
            }) 
            .then(response => response.json()) 
            .then(
                data => {
                    setLoading(false);
                    dialog.show({
                        body: data.message,
                        actions: [
                            Dialog.OKAction(() => {
                                if (data.status) {
                                    let fields = fileData;
                                    fields['file_name'] = "";
                                    fields['comments'] = "";
                                    fields['upload_file'] = "";
                                    setFileData(fields);
                                    setFileLable('Choose file');
                                    fetchFiles(1);
                                    return props.fetchEventsData();
                                }
                            })
                        ],
                        bsSize: 'small'
                    });
                }
            ); 
        }
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <h2>
                        <Link onClick={props.handleShwoHide} className="text-success"><i className="fa fa-bars"></i> File</Link>
                        <Button className="btn-info ml-2 float-right" onClick={() => {fetchFiles(1); return props.fetchEventsData()}}><i className="fa fa-refresh"></i> Refresh</Button>
                    </h2>
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                <Form.Label>{t("lable.file_name")} <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" placeholder="File Description / Details"
                                        name="file_name"
                                        value={fileData ? fileData.file_name : ""}
                                        onChange={handleChange}
                                    />
                                    <Form.Text className="text-danger">{(!errors) ? "" : errors.file_name}</Form.Text>
                                </Form.Group>
                            </Col> 
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>{t("lable.comments")} <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" placeholder="File Comments"
                                        name="comments"
                                        value={fileData ? fileData.comments : ""}
                                        onChange={handleChange}
                                    />
                                    <Form.Text className="text-danger">{(!errors) ? "" : errors.comments}</Form.Text>
                                </Form.Group>
                            </Col> 
                            <Col md={6}>
                                <Form.Group className="text-left">
                                    <Form.File label={fileLable} custom
                                        name="upload_file"
                                        onChange={handleChange}
                                    />
                                    <Form.Text className="text-danger">{(!errors) ? "" : errors.upload_file}</Form.Text>
                                </Form.Group>
                            </Col> 
                            <Col md={6} className="text-right">
                                <Button className="ml-2" onClick={handleSubmit}><i class="fa fa-arrow-circle-left" aria-hidden="true"></i> Submit</Button>
                                {/* <Button className="ml-2"> Clear</Button> */}
                            </Col> 
                        </Row>
                    </Form>
                </Card.Body>
                <hr style={{marginBottom: "0px"}}/>
                <DataTable
                    columns={columns}
                    data={tableData}
                    className="pr_datatable"
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    noHeader={true}
                    pointerOnHover
                    highlightOnHover
                />
            </Card>
            <Loader show={loading}/>
        </>
    );
}

export default File;