import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Form, InputGroup, Alert } from "react-bootstrap";
import  * as Constants from "../../config/Constants";
import { getToken } from "../../helpers/Session";
import Loader from '../header/Loader';
import Dialog from "react-bootstrap-dialog";
import { AlertContext } from "../context/Alert";
import { LocalizationContext } from "../context/localization/localization.context";

import sos_logo from "../../assets/image/sos-logo.png";

import "./authenticate.scss";

function ForgotPassword(props) {
    const { t, setLocale } = React.useContext(LocalizationContext);
    const isEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const { dialog } = React.useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);    
    const [fields, setFields] = useState({
        email: ""
    });

    useEffect(() => {
        const token = getToken();
        if (token !== null) {
            props.history.push("/"); // redirect to dashboard
        }
    }, [])

    const handleValidation = () => {
        let field = fields;
        let errors = {};
        let formIsValid = true;

        if (!field["email"]) {
            formIsValid = false;
            errors["email"] = t('errors.email');
        } else if (!isEmail.test(field["email"])) {
            formIsValid = false;
            errors["email"] = t('errors.valid_email');
        }
        
        setErrors(errors);
        return formIsValid;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFields({...fields, [name]: value});    
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            setErrors(null);
            setLoading(true);
            const formData = fields;
            fetch(Constants.API_SEND_MAIL_RESET_PASSWORD, { 
                method: "POST", 
                body: JSON.stringify(formData) 
            }) 
            .then(response => response.json()) 
            .then(
                data => {
                    setLoading(false);
                    dialog.show({
                        body: data.message,
                        actions: [
                            Dialog.OKAction(() => {
                                if (data.status) {
                                    props.history.push("/login");
                                }
                            })
                        ],
                        bsSize: 'small'
                    });
                }
            ); 
        }
    }

    return (
        <>
            <Container fluid className="container-login100">
                <Row style={{width: "54%"}}>
                    <Col md={6} className="login100-pic">
                        <a href={Constants.WEBSITE_BASE_URL}><Image src={sos_logo} alt="sos logo" style={{width: "100%"}} /></a>
                    </Col>
                    <Col md={6} className="bl">
                        { 
                            (errors) ?
                                <Alert variant="danger">
                                    {errors.email}
                                </Alert>
                            : ""
                        }
                        <Form>
                            <Form.Row className="mb-4">
                                <Form.Label srOnly> Username </Form.Label>
                                <InputGroup className="mb-2">
                                    <InputGroup.Prepend>
                                        <InputGroup.Text><i className="fa fa-envelope" aria-hidden="true" style={{fontSize: "15px"}}></i></InputGroup.Text>
                                    </InputGroup.Prepend>
                                    <Form.Control type="text" 
                                        name="email"
                                        onChange={handleChange} 
                                        placeholder={t('lable.email')} />
                                </InputGroup>
                            </Form.Row>
                            <div className="container-login100-form-btn mb-3">
                                <button className="login100-form-btn" onClick={handleSubmit}>
                                Submit
                                </button>
                            </div>
                            <div className="container-login100-form-btn mb-3">
                                <a href="login" className="login100-form-lable">Login</a>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Loader show={loading}/>
        </>
    );
}

export default ForgotPassword;