import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Nav from "../header/Menu";
import Footer from "../header/Footer";
import Login from "../authenticate/Login";
import ForgotPassword from "../authenticate/ForgotPassword";
import ResetPassword from "../authenticate/ResetPassword";
import Register from "../authenticate/Register";
import Dashboard from "../dashboard/Dashboard";
import Notification from "../dashboard/Notification";
import PersonalDetails from "../profile/PersonalDetails";
import BusinessDetails from "../profile/BusinessDetails";
import ChangePassword from "../profile/ChangePassword";
import AddProject from "../project/AddProject";
import ProjectList from "../project/ProjectList";
import ProjectDetails from "../project/ProjectDetails";
import PrivateRoute from "../authenticate/PrivateRoute";
import PageNotFound from "../error/PageNotFound";
import { AlertProvider } from "../context/Alert";
import Payment from "../payment/Payment";
import PaymentReceipt from "../payment/PaymentReceipt";
import { LocalizationContextProvider } from "../context/localization/localization.context";
import { UserContextProvider } from "../context/user.context";

function App() {
    return (
        <LocalizationContextProvider>
            <UserContextProvider>
                <AlertProvider>
                    <BrowserRouter>
                        <Switch>
                            <Route path="/login" component={Login} />
                            <Route path="/register" component={Register} />
                            <Route path="/forgot-password" component={ForgotPassword} />
                            <Route path="/reset-password" component={ResetPassword} />
                            <Route path="/error-404" component={PageNotFound} />
                            <Route path="/payment-receipt/:quotationId" component={PaymentReceipt} />
                            <Nav>
                                <PrivateRoute exact path="/" component={Dashboard} />
                                <PrivateRoute exact path="/notification" component={Notification} />
                                <PrivateRoute path="/personal-details" component={PersonalDetails} />
                                <PrivateRoute path="/business-details" component={BusinessDetails} />
                                <PrivateRoute path="/change-password" component={ChangePassword} />
                                <PrivateRoute path="/add-project" component={AddProject} />
                                <PrivateRoute path="/project-list" component={ProjectList} />
                                <PrivateRoute path="/project-details/:projectId" component={ProjectDetails} />
                                <PrivateRoute path="/payment/:projectId" component={Payment} />
                                <Footer/>
                            </Nav>
                        </Switch>
                    </BrowserRouter>
                </AlertProvider>
            </UserContextProvider>
        </LocalizationContextProvider>
    );
}

export default App;
