export default {
  "lable": {
    "first_name": "First Name",
    "middle": "Middle Name",
    "last_name": "Last Name",
    "email": "Email",
    "mobile_number": "Mobile Number",
    "alternate_contact_number": "Alternate Contact Number",
    "requirement": "Type in your extact requirement / details",
    "otp":"Enter OTP Code",
    "address":"Address Details",
    "choose_photo":"Choose Photo",
    "upload_photo": "Upload Photo",
    "company_logo_upload": "Company Logo Upload",
    "company_phone": "Company Phone",
    "company_name": "Company Name",
    "company_address":"Company Address Details",
    "gst_number": "GST Number",
    "pan_number": "PAN Number",
    "payment_date": "Payment Date",
    "payment_mode": "Payment Mode ",
    "cheque_no": "Cheque No",
    "account_holder_name": "Account Holder Name ",
    "bank_name": "Bank Name",
    "bank_branch": "Bank Branch",
    "neft_reference_number": "NEFT Reference Number",
    "amount": "Amount",
    "project_name": "Project Name",
    "project_details": "Project Details",
    "file_name": "File Name",
    "comments": "Comments",
    "full_name": "Your Name",
    "subject": "Subject",
    "message": "Message"
  },
  "dashboard": "Dashboard",
  "button": {
    "register": "Register",
    "send_otp": "Send OTP",
    "resend_otp": "Resend OTP",
  },
  "errors": {
    "only_number": "Please enter only number!",
    "number_10_digit": "Please enter 10 digit mobile number!",
    "valid_email" : "Email is not valid!",
    "first_name": "Please enter the First Name!",
    "middle": "Please enter the Middle Name!",
    "last_name": "Please enter the Last Name!",
    "email": "Please enter the Email!",
    "mobile_number": "Please enter the Personal Contact!",
    "alternate_contact_number": "Please enter the Alternate Contact!",
    "requirement": "Please enter requirement / details!",
    "otp":"Please enter the otp number!",
    "current_password": "Please enter the Current Password!",
    "password":"Please enter the Password!",
    "password_must_be_8_char": "Password must be the 8 characters, uppercase, lowercase, number and special character!",
    "confirm": "Please enter the Confirm Password!",
    "password_do_not_match": "Passwords do not match!",
    "gst_must_be_15_char": "GST number must be 15 charecters.",
    "company_name": "Please enter the Company Name!",
    "gst": "Please enter the GST Number!",
    "pan": "Please enter the PAN Number!",
    "phone": "Please enter the Phone Number!",
    "payment_date": "Please select the Payment Date!",
    "payment_mode": "Please select the Payment mode!",
    "cheque_no": "Please Enter the Cheque Number!",
    "account_holder_name": "Please Enter the Account Holder Name!",
    "bank_name": "Please Enter the Bank Name!",
    "bank_branch": "Please Enter the Bank Branch Name!",
    "neft_reference_number": "Please Enter the NEFT Reference Number!",
    "amount": "Please Enter the Bank Name!",
    "project_name": "Please enter the Project Name!",
    "project_details": "Please enter the Project Details!",
    "file_name": "Please enter the File Name!",
    "comments": "Please enter the Comments!",
    "upload_file": "Please select the file!",
    "full_name": "Please enter the Your Name!",
    "subject": "Please enter the Subject!",
    "message": "Please enter the Message!"
  },
  "title": {
    "New Customer Registration": "New Customer Registration"
  }
}