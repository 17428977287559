import React from "react";
// import * as Localization from 'expo-localization';
import i18n from 'i18n-js';

import en from "./en";
import jp from "./jp";

const {createContext, useState, useMemo} = React;

export const LocalizationContext = createContext();

export function LocalizationContextProvider({children}) {
  const [locale, setLocale] = useState("en");
  const localizationContext = useMemo(
    () => ({
      t: (scope, options) => i18n.t(scope, { locale, ...options }),
      locale,
      setLocale,
    }),
    [locale]
  );

  i18n.translations = {
    en, jp
  };
  i18n.locale = locale;
  i18n.fallbacks = true;

  return (
    <LocalizationContext.Provider value={localizationContext}>
      {children}
    </LocalizationContext.Provider>
  );
}