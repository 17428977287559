import React from "react";
import { setUserSession, removeUserSession, getToken, getUser, getUserId } from "../../helpers/Session";

const { createContext, useState } = React;

export const UserContext = createContext();

export function UserContextProvider({ children }) {
    const [userData, setUserData] = useState(() => { 
        return (getUser()) ? getUser() : null 
    });
    const [userId, setUserId] = useState(() => { 
        return (getUserId()) ? getUserId() : null 
    });
    const [token, setToken] = useState(() => { 
        return (getToken()) ? getToken() : null 
    });

    const setLocalStorage = (token, user) => {
        setUserSession(token, user);
        
        setUserData(getUser());
        setUserId(getUserId());
        setToken(getToken());
    } 

    const removeLocalStorage = () => {
        removeUserSession();

        setUserData(null);
        setUserId(null);
        setToken(null);
    } 

    return (
        <UserContext.Provider value={{
            setLocalStorage,
            removeLocalStorage,
            userData,
            userId,
            token
        }}>
            {children}
        </UserContext.Provider>
    );
}