export default {
  "lable": {
    "first_name": "ファーストネーム",
    "middle": "ミドルネーム",
    "last_name": "苗字",
    "email": "Eメール",
    "mobile_number": "携帯電話番号",
    "alternate_contact_number": "緊急連絡先番号",
    "requirement": "正確な要件/詳細を入力してください",
    "otp":"ここにOTPコードを入力"
  },
  "dashboard": "ダッシュボード",
  "button": {
    "register": "登録",
    "send_otp": "OTPを送信"
  },
  "errors": {
    "only_number": "数字のみ入力してください！",
    "number_10_digit": "10桁の携帯電話番号を入力してください！",
    "valid_email" : "メールが無効です！",
    "first_name": "名を入力してください！",
    "middle": "ミドルネームを入力してください！",
    "last_name": "姓を入力してください！",
    "email": "メールアドレスを入力してください！",
    "mobile_number": "個人の連絡先を入力してください！",
    "alternate_contact_number": "緊急連絡先を入力してください！",
    "requirement": "要件/詳細を入力してください！",
    "otp":"OTP番号を入力してください！"
  },
  "title": {
    "New Customer Registration": "新規顧客登録"
  }
}