export const getProjectStatusArr = () => {
    const projec_status = {
        1: {
            status: 'Register',
            color: ''
        },
        2: {
            status: 'Quotation',
            color: ''
        },
        3: {
            status: 'Review',
            color: ''
        },
        4: {
            status: 'Payment',
            color: ''
        },
        5: {
            status: 'Audit Report',
            color: ''
        }
    }
    return projec_status;
}

export const getProjectSubStatusArr = () => {
    const projec_sub_status = {
        a: {
            status: 'a',
            display_name: 'In-progress',
            color: 'primary',
        },
        b: {
            status: 'd',
            display_name: 'On Hold',
            color: 'warning',
        },
        c: {
            status: 'c',
            display_name: 'Attention Needed',
            color: 'danger',
        },
        d: {
            status: '',
            display_name: 'Done',
            color: 'success',
        }

    }
    return projec_sub_status;
}

export const getProjectPaymentSubStatusArr = () => {
    const project_payment_sub_status = {
        a: {
            status: 'a',
            display_name: 'In-progress',
            color: 'primary',
        },
        b: {
            status: 'd',
            display_name: 'On Hold',
            color: 'warning',
        },
        c: {
            status: 'c',
            display_name: 'Attention Needed',
            color: 'danger',
        },
        d: {
            status: 'd',
            display_name: 'Done',
            color: 'success',
        },
        e: {
            status: 'e',
            display_name: 'Quotation Not send',
            color: 'btn-primary'
        },
        f: {
            status: 'f',
            display_name: 'Quotation Pending for clients approval',
            color: 'btn-warning'
        },
        g: {
            status: 'g',
            display_name: 'Quotation Rejected by client',
            color: 'btn-danger'
        },
        h: {
            status: 'h',
            display_name: 'Payment Not Received',
            color: 'warning',
        },
        i: {
            status: '',
            display_name: 'Payment Received',
            color: 'success',
        }
    }
    return project_payment_sub_status;
}

