import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Card, Button, Form, Col, Row, Table } from "react-bootstrap";
import { Link, useParams } from 'react-router-dom';
import DataTable from "react-data-table-component";
import Moment from 'moment';
import Dialog from "react-bootstrap-dialog";
import { AlertContext } from "../context/Alert";
import Loader from '../header/Loader';
import  * as Constants from "../../config/Constants";
import { UserContext } from "../context/user.context";
import { LocalizationContext } from "../context/localization/localization.context";

function Query(props) {
    const { token, userData } = React.useContext(UserContext);
    const { t } = React.useContext(LocalizationContext);
    const { dialog } = React.useContext(AlertContext);
    let { projectId } = useParams();
    const [loading, setLoading] = useState(false);
    const [showCompose, setShowCompose] = useState('hide');    
    const [showReplyForm, setShowReplyForm] = useState('hide');    
    const [errors, setErrors] = useState(null);
    //dataTable
    const [tableData, setTableData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [composeData, setComposeData] = useState({
        project_id: projectId,
        query_code: "",
        name: userData.first_name + " " + userData.last_name,
        subject: "",
        message: "",
        reply: ""
    });
    
    useEffect(() => {
        if (props.queryload) {
            fetchQuery(1);
        }
    }, [props.queryload]);

    const fetchQuery = (page, size = perPage) => {
        setLoading(true);
        fetch(Constants.API_GET_QUERY_BY_PROJECT + projectId + "?page=" + page + "&per_page=" + size , { 
            method: "GET",
            headers: { 
                "Authorization": "Bearer " + token
            }
        }) 
        .then(response => response.json())
        .then(resData => {
                setLoading(false);
                setTableData(resData.queryData.data);
                setTotalRows(resData.queryData.total);
            }
        );
    };

    const SampleExpandedComponent = useCallback (
        ({ data }) => {
            const replyData = JSON.parse(data.query_reply_data);
            return (
                <div className="table-row">
                    <Table>
                        {
                            (replyData.length > 0) ? 
                                replyData.map((row, i) =>
                                    <tr key={i} style={{backgroundColor: "#F7F7F7"}}>
                                        <td style={{width: "85px", borderBottom: "1px solid rgba(0,0,0,.12)"}} className="text-center">
                                            <label className="p-2 text-center rounded-circle bg-info text-white" style={{width: "32px", height: "32px", marginLeft: "20px"}}>
                                                {i+1}
                                            </label>
                                        </td>
                                        <td style={{width: "165px", borderBottom: "1px solid rgba(0,0,0,.12)"}}>{Moment(row.created_ts).format('DD-MM-YYYY h:mm A')}</td>
                                        <td style={{width: "160px", borderBottom: "1px solid rgba(0,0,0,.12)"}}>{row.name}</td>
                                        <td style={{width: "160px", borderBottom: "1px solid rgba(0,0,0,.12)"}}>{row.subject}</td>
                                        <td style={{borderBottom: "1px solid rgba(0,0,0,.12)"}}>{row.message}</td>
                                        <td style={{width: "120px", borderBottom: "1px solid rgba(0,0,0,.12)"}}></td>
                                    </tr>
                                )
                            :
                                <tr style={{backgroundColor: "#F7F7F7"}}><td colSpan="6" className="text-center font-bold">No Reply</td></tr>
                        }
                    </Table>
                </div>
            );
        }
    );

    const handleReply = useCallback (
        row => async () => {
            let fields = composeData;
            const showHide = (showReplyForm === "show") ? "hide" : "show";
            setShowReplyForm(showHide);
            setShowCompose('hide');
            if (showHide == 'show') {
                fields['query_code'] = row.query_code;
                fields['subject'] = row.subject;
                fields['message'] = "";
            } else {
                fields['query_code'] = "";
                fields['subject'] = "";
                fields['message'] = "";
            }
            setComposeData(fields);
        }
    );

    const columns = useMemo(
        () => [
            {
                cell: row => 
                    <div>
                        <label className="p-2 text-center rounded-circle bg-success text-white" style={{width: "32px", marginLeft: "-25px"}}>
                                {row.reply_count}
                        </label>
                    </div>,
                width: '20px'
            },{
                name: "Created Date",
                selector: "created_ts",
                sortable: true,
                cell: row => <div>{Moment(row.created_ts).format('DD-MM-YYYY h:mm A')}</div>,
                width: '165px'
            }, {
                name: "Name",
                selector: "name",
                sortable: true,
                wrap: true,
                width: '160px'
            }, {
                name: "Subject",
                selector: "subject",
                sortable: true,
                wrap: true,
                width: '160px'
            }, {
                name: "Message",
                selector: "message",
                sortable: true,
                wrap: true,
            }, {
                name: "Reply",
                cell: row => <button className="btn btn-info" onClick={handleReply(row)}><i className="fa fa-reply"></i> Reply</button>,
                width: '125px',
            }
        ]
    );

    const handlePageChange = page => {
        fetchQuery(page);
        setCurrentPage(page);
    };
    
    const handlePerRowsChange = async (newPerPage, page) => {
        fetchQuery(page, newPerPage);
        setPerPage(newPerPage);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setComposeData({...composeData, [name]: value});
    }

    const handleValidation = (reply) => {
        let fields = composeData;
        let errors = {};
        let formIsValid = true;
        if (reply == 0) {
            if (fields["name"] === "") {
                formIsValid = false;
                errors["name"] = t('errors.full_name');
            }
            if (fields["subject"] === "") {
                formIsValid = false;
                errors["subject"] = t('errors.subject');
            }
        }
        if (fields["message"] === "") {
            formIsValid = false;
            errors["message"] = t('errors.message');
        }
        setErrors(errors);
        return formIsValid;
    }
    
    const handleSubmit = (reply, e) => {
        e.preventDefault();
        if (handleValidation(reply)) {
            let fields = composeData;
            fields['reply'] = reply;
            setComposeData(fields);
            setLoading(true);
            fetch(Constants.API_QUERY, { 
                method: 'POST', 
                body: JSON.stringify(composeData), 
                headers: { 
                    "Authorization": "Bearer " + token
                }
            }) 
            .then(response => response.json()) 
            .then(
                data => {
                    setLoading(false);
                    dialog.show({
                        body: data.message,
                        actions: [
                            Dialog.OKAction(() => {
                                if (data.status) {
                                    setShowCompose('hide');
                                    setShowReplyForm('hide');
                                    fetchQuery(1);
                                    return props.fetchEventsData();
                                }
                            })
                        ],
                        bsSize: 'small'
                    });
                }
            ); 
        }
    }

    const composeHideShow = () => {
        let fields = composeData;
        fields['subject'] = "";
        fields['message'] = "";
        setComposeData(fields);
        const showHide = (showCompose === "show") ? "hide" : "show";
        setShowCompose(showHide);
        setShowReplyForm('hide');
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <h2>
                        <Link onClick={props.handleShwoHide} className="text-success"><i className="fa fa-bars"></i> Queries / Notes / Feedback</Link>
                        <Button className="btn-info ml-2 float-right" onClick={() => {fetchQuery(1); return props.fetchEventsData()}}><i className="fa fa-refresh"></i> Refresh</Button>
                    </h2>
                </Card.Header>
                <Card.Body>
                    <Button variant="outline-secondary mb-3" onClick={composeHideShow}><i className="fa fa-plus"></i> Compose</Button>
                    <Form className={showCompose}>
                        <Form.Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>{t('lable.full_name')} <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" 
                                        name="name"
                                        value={(composeData.name !== "") ? composeData.name : ""}
                                        onChange={handleChange}
                                    />
                                    <Form.Text className="text-danger">{(!errors) ? "" : errors.name}</Form.Text>
                                </Form.Group>
                            </Col> 
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>{t('lable.subject')} <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" 
                                        name="subject"
                                        onChange={handleChange}
                                        value={(composeData.subject !== "") ? composeData.subject : ""}
                                    />
                                    <Form.Text className="text-danger">{(!errors) ? "" : errors.subject}</Form.Text>
                                </Form.Group>
                            </Col> 
                            <Col md={12}>
                                <Form.Group>
                                    <Form.Label>{t('lable.message')} <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" 
                                        as="textarea"
                                        name="message"
                                        value={(composeData.message !== "") ? composeData.message : ""}
                                        onChange={handleChange}
                                        rows="3"
                                    />
                                    <Form.Text className="text-danger">{(!errors) ? "" : errors.message}</Form.Text>
                                </Form.Group>
                            </Col> 
                        </Form.Row>
                        <Row>
                            <Col md={12} className="text-center mb-3">
                                <Button className="btn-default" onClick={(e) => handleSubmit(0, e)}><i class="fa fa-arrow-circle-left" aria-hidden="true"></i> Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                    <Form className={showReplyForm}>
                        <Form.Row>
                            <Col md={12}>
                                <Form.Group>
                                    <Form.Label>{t('lable.message')} <span className="text-danger">*</span></Form.Label>
                                    <Form.Control type="text" 
                                        as="textarea"
                                        name="message"
                                        value={(composeData.message !== "") ? composeData.message : ""}
                                        onChange={handleChange}
                                        rows="3"
                                    />
                                    <Form.Text className="text-danger">{(!errors) ? "" : errors.message}</Form.Text>
                                </Form.Group>
                            </Col> 
                        </Form.Row>
                        <Row>
                            <Col md={12} className="text-center mb-3">
                                <Button className="btn-default" onClick={(e) => handleSubmit(1, e)}><i class="fa fa-arrow-circle-left" aria-hidden="true"></i> Submit</Button>
                            </Col>
                        </Row>
                    </Form>
                </Card.Body>
                <hr style={{marginBottom: "0px"}}/>
                <DataTable
                    columns={columns}
                    data={tableData}
                    className="pr_datatable"
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    noHeader={true}
                    pointerOnHover
                    highlightOnHover
                    expandableRows
                    expandableRowsComponent={<SampleExpandedComponent />}
                />
            </Card>
            <Loader show={loading}/>
        </>
    );
}

export default Query;