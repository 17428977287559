import React, { useEffect, useState} from 'react';
import { Button, Modal, Table } from "react-bootstrap";
import Moment from 'moment';

function CommonModal(props) {
    const [paymentDetails, setPaymentDetails] = useState(null);

    useEffect(() => {
        if (props.data.data !== "") {
            setPaymentDetails(JSON.parse(props.data.data.payment_details));
        }
    }, [props.data]);

    const capitalize = (str) => {
        if (typeof str !== 'string') return ''
        let parts = str.split(' '),
            len = parts.length,
            i, words = [];
        for (i = 0; i < len; i++) {
            var part = parts[i];
            var first = part[0].toUpperCase();
            var rest = part.substring(1, part.length);
            var word = first + rest;
            words.push(word);

        }
        return words.join(' ');
    }
    
    return (
      <Modal
        {...props}
        size={(props.size) ? props.size : 'md'}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.data.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Table className="cust-table">
            <tbody>
                <tr>
                    <th class="border-top-0">Project Name:</th>
                    <td className="border-top-0"> {props.data.data.project_name}</td>
                </tr>
                <tr>
                    <th>Project Code:</th>
                    <td>{props.data.data.project_code}</td>
                </tr>
                <tr>
                    <th>Quotation Amount:</th>
                    <td>Rs. {parseFloat(props.data.data.amount).toFixed(2)}</td>
                </tr>
                <tr>
                    <th>Payment Date:</th>
                    <td>{Moment(props.data.data.payment_date).format('DD-MM-YYYY')}</td>
                </tr>
                <tr>
                    <th>Paid Amount:</th>
                    <td>Rs. {(paymentDetails) ? parseFloat(paymentDetails.payment_amount).toFixed(2) : "--"} </td>
                </tr>
                <tr>
                    <th>Payment Mode:</th>
                    <td> {props.data.data.payment_mode} </td>
                </tr>
                {
                    (props.data.data.payment_mode === 'Cheque') ?
                        <>
                            <tr>
                                <th>Cheque No:</th>
                                <td> {(paymentDetails) ? paymentDetails.cheque_no : "--"} </td>
                            </tr>
                            <tr>
                                <th>Account Holder Name:</th>
                                <td> {(paymentDetails) ? capitalize(paymentDetails.account_holder_name) : "--"} </td>
                            </tr>
                            <tr>
                                <th>Bank Name:</th>
                                <td> {(paymentDetails) ? capitalize(paymentDetails.bank_name) : "--"} </td>
                            </tr>
                            <tr>
                                <th>Bank Branch:</th>
                                <td> {(paymentDetails) ? capitalize(paymentDetails.bank_branch) : "--"} </td>
                            </tr>
                        </>
                    :
                        ""
                }
                {
                    (props.data.data.payment_mode === 'NEFT') ?
                        <>
                            <tr>
                                <th>NEFT Reference No:</th>
                                <td> {(paymentDetails) ? paymentDetails.neft_reference_number : "--"} </td>
                            </tr>
                        </>
                    :
                        ""
                }
                
            </tbody>
            </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
}

export default CommonModal;
