import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image, Form, InputGroup, Alert } from "react-bootstrap";
import  * as Constants from "../../config/Constants";
import { getToken } from "../../helpers/Session";
import Loader from '../header/Loader';
import Dialog from "react-bootstrap-dialog";
import { AlertContext } from "../context/Alert";
import { LocalizationContext } from "../context/localization/localization.context";

import sos_logo from "../../assets/image/sos-logo.png";

import "./authenticate.scss";
function ResetPassword(props) {
    const { t } = React.useContext(LocalizationContext);
    const queryString = require('query-string');
    const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    const { dialog } = React.useContext(AlertContext);
    const [passwordShow, setPasswordShow] = useState({
        hideShow: "hide",
        type: "password"
    });
    const [confPasswordShow, setConfPasswordShow] = useState({
        hideShow: "hide",
        type: "password"
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);    
    const [fields, setFields] = useState({
        token: "",
        password: "",
        confirmPassword: ""
    });

    useEffect(() => {
        const token = getToken();
        if (token !== null) {
            props.history.push("/"); // redirect to dashboard
        }

        const param = queryString.parse(props.location.search);
        fetch(Constants.API_VALIDATE_RESET_TOKEN, { 
            method: "POST", 
            body: JSON.stringify({token: param['token']})  
        }) 
        .then(response => response.json()) 
        .then(
            data => {
                if (data.status) {
                    let field =  fields;
                    field['token'] = data.token;        
                    setFields(field);
                } else {
                    props.history.push("/error-404");
                }
            }
        );
    }, [])

    const handleValidation = () => {
        let field = fields;
        let errors = {};
        let formIsValid = true;

        if (field["password"] === "") {
            formIsValid = false;
            errors["password"] = t('errors.password');
        } else if (!regularExpression.test(field["password"])) {
            formIsValid = false;
            errors["password"] = t('errors.password_must_be_8_char');
        }

        if (field["confirmPassword"] === "") {
            formIsValid = false;
            errors["confirmPassword"] = t('errors.confirm');
        } else if (field["password"] !== field["confirmPassword"]) {
            formIsValid = false;
            errors["confirmPassword"] = t('errors.password_do_not_match');
            let field =  fields;
            field['password'] = "";        
            field['confirmPassword'] = "";        
            setFields(field);
        }
        
        setErrors(errors);
        return formIsValid;
   }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFields({...fields, [name]: value});
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (handleValidation()) {
            setErrors(null);
            setLoading(true);
            const formData = fields;
            fetch(Constants.API_FORGOT_PASSWORD, { 
                method: "POST", 
                body: JSON.stringify(formData) 
            }) 
            .then(response => response.json()) 
            .then(
                data => {
                    setLoading(false);
                    dialog.show({
                        body: data.message,
                        actions: [
                            Dialog.OKAction(() => {
                                if (data.status) {
                                    props.history.push("/login");
                                } else {
                                    setFields({...fields, ['password']: ""});
                                    setFields({...fields, ['confirmPassword']: ""});
                                }
                            })
                        ],
                        bsSize: 'small'
                    });
                }
            ); 
        }
    }

    const handleHideShowPassword = (textbox, value, e) => {
        if (textbox === 'password') {
            setPasswordShow({
                hideShow: value,
                type: (value === "show") ? "text" : "password"
            })
        } else {
            setConfPasswordShow({
                hideShow: value,
                type: (value === "show") ? "text" : "password"
            })
        }
    }

    return (
        <>
            <Container fluid className="container-login100">
                <Row style={{width: "54%"}}>
                    <Col md={6} className="login100-pic">
                        <a href={Constants.WEBSITE_BASE_URL}><Image src={sos_logo} alt="sos logo" style={{width: "100%"}} /></a>
                    </Col>
                    <Col md={6} className="bl">
                        { 
                            (errors) ?
                                <Alert variant="danger">
                                    {errors.password}
                                    <br/>
                                    {errors.confirmPassword}
                                </Alert>
                            : ""
                        }
                        <Form>
                            <Form.Row className="mb-4">
                                <Form.Label srOnly> Password </Form.Label>
                                <InputGroup className="mb-2">
                                    <Form.Control type={passwordShow.type}
                                        name="password"
                                        onChange={handleChange}
                                        value={(fields) ? fields.password : ""}
                                        placeholder="Password" />
                                    <InputGroup.Prepend>
                                        {
                                            (passwordShow.hideShow === "hide") ?
                                                <InputGroup.Text><i className="fa fa-eye-slash" onClick={(e) => handleHideShowPassword('password', 'show', e)}></i></InputGroup.Text>
                                            :
                                                <InputGroup.Text><i className="fa fa-eye" onClick={(e) => handleHideShowPassword('password', 'hide', e)}></i></InputGroup.Text>
                                        }
                                    </InputGroup.Prepend>
                                </InputGroup>
                            </Form.Row>
                            <Form.Row className="mb-4">
                                <Form.Label srOnly> Confirm Password </Form.Label>
                                <InputGroup className="mb-2">
                                    <Form.Control type={confPasswordShow.type}
                                        name="confirmPassword"
                                        onChange={handleChange}
                                        value={(fields) ? fields.confirmPassword : ""}
                                        placeholder="confirmPassword" />
                                    <InputGroup.Prepend>
                                        {
                                            (confPasswordShow.hideShow === "hide") ?
                                                <InputGroup.Text><i className="fa fa-eye-slash"  onClick={(e) => handleHideShowPassword('confpassword', 'show', e)}></i></InputGroup.Text>
                                            :
                                                <InputGroup.Text><i className="fa fa-eye" onClick={(e) => handleHideShowPassword('confpassword', 'hide', e)}></i></InputGroup.Text>
                                        }
                                    </InputGroup.Prepend>
                                </InputGroup>
                            </Form.Row>
                            <div className="container-login100-form-btn mb-3">
                                <button className="login100-form-btn" onClick={handleSubmit}>
                                <i className="fa fa-arrow-circle-left" aria-hidden="true"></i> Submit
                                </button>
                            </div>
                            <div className="text-center p-t-12 text-wheth">
                                <Link to="login" className="txt2">Login</Link>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Loader show={loading}/>
        </>
    );
}

export default ResetPassword;