import React from "react";

function Footer() {
    return (
        <>
            <div className="container-fluid footer bg-section-5 no-print">
                <div className="container py-2">
                    <div className="row">
                        <div className="col-6 text-white text-left">
                            <p className="float-left">Copyright © {(new Date().getFullYear())} | All rights reserved</p>
                        </div>
                        <div className="col-6 text-white text-right">
                            <p className="float-right">
                                <a href="https://miotweb.com/" target="_blank">Developed by Miotweb Technologies Pvt. Ltd.</a>
                            </p>
                        </div>
                        <div style={{display: "none"}}>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer;