import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Image, Form } from "react-bootstrap";
import Dialog from "react-bootstrap-dialog";
import { AlertContext } from "../context/Alert";
import { UserContext } from "../context/user.context";
import  * as Constants from "../../config/Constants";
import Loader from '../header/Loader';
import company_logo from "../../assets/image/company_logo.png";
import { LocalizationContext } from "../context/localization/localization.context";

import './project.scss';
const queryString = require('query-string');

function AddProject(props) {
    const { token, userData } = React.useContext(UserContext);
    const { t } = React.useContext(LocalizationContext);
    const { dialog } = React.useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);    
    const [projectData, setProjectData] = useState({
        project_id: "",
        company_id: userData.company_id,
        project_name: "",
        project_details: ""
    });

    function fetchProjectData(projectId) {
        setLoading(true);
        fetch(Constants.API_PROJECT + projectId, { 
            method: "GET",
            headers: { 
                "Authorization": "Bearer " + token
            }
        }) 
        .then(response => response.json()) 
        .then(
            data => {
                setLoading(false);
                if (data.status) {
                    setProjectData({
                        project_id: data.projectData.project_id,
                        company_id: data.projectData.company_id,
                        project_name: data.projectData.project_name,
                        project_details: data.projectData.project_details
                    })
                }
            }
        );
    }

    useEffect(() => {
        const param = queryString.parse(props.location.search);
        if (param['project_id'] && param['project_id'] !== "") {
            fetchProjectData(param['project_id']);
        }
    }, []);
    
    function handleChange(e) {
        const { name, value } = e.target;
        setProjectData({ ...projectData, [name]: value});
    }

    function handleValidation() {
        let fields = projectData;
        let errors = {};
        let formIsValid = true;

        if (fields["project_name"] === "") {
            formIsValid = false;
            errors["project_name"] = t("errors.project_name");;
        }
        if (fields["project_details"] === "") {
            formIsValid = false;
            errors["project_details"] = t("errors.project_details");;
        }

        setErrors(errors);
        return formIsValid;
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (handleValidation()) {
            let url = Constants.API_PROJECT;
            let method = "POST";
            if (projectData['project_id'] !== "") {
                url = Constants.API_PROJECT + projectData['project_id'];
                method = "PUT";
            }
            const formData = {
                "company_id": projectData['company_id'],
                "project_name": projectData['project_name'],
                "project_details": projectData['project_details']
            }
            if (projectData['company_id'] && projectData['company_id'] !== "") {
                setLoading(true);
                fetch(url, { 
                    method: method, 
                    body: JSON.stringify(formData), 
                    headers: { 
                        "Authorization": "Bearer " + token
                    }
                }) 
                .then(response => response.json()) 
                .then(
                    data => {
                        setLoading(false);
                        dialog.show({
                            body: data.message,
                            actions: [
                                Dialog.OKAction(() => {
                                    if (data.status) {
                                        props.history.push("/project-list");
                                    }
                                })
                            ],
                            bsSize: 'small'
                        });
                    }
                );
            } else {
                dialog.show({
                    body: "Complete your Business Profile first",
                    actions: [
                        Dialog.OKAction(() => {
                            props.history.push("/business-details");
                        })
                    ],
                    bsSize: 'small'
                });
            }
        }
    }

    return (
        <>
            <Container className="mt-cust bread-crumb">
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={3} className="mb-3">
                                {
                                    (userData && userData.company_logo !== null && userData.company_logo !== "") ?
                                        <Image src={Constants.API_BASE_URL + userData.company_logo} width="90" height="90"></Image> 
                                    : 
                                        <Image src={company_logo} width="90" height="90"></Image> 
                                }
                            </Col>
                            <Col md={6} className="mb-3 text-center">
                                <h2>Add New Project</h2>
                            </Col>
                            <Col md={3} className="mb-3">
                                <a href="/" className="btn btn-default float-right">
                                    <i className="fa fa-chevron-left"></i> Back to Dashboard
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="form-container">
                <Row>
                    <Col md={12}>
                        <Row className="justify-content-md-center">
                            <Col md={10} className="mb-3">
                                <Form>
                                    <Row className="justify-content-md-center">
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>{t("lable.project_name")} <span className="text-danger">*</span></Form.Label>
                                                <Form.Control type="text"
                                                    name="project_name"
                                                    value={projectData ? projectData.project_name : ""}
                                                    onChange={handleChange}
                                                />
                                                <Form.Text className="text-danger">{(!errors) ? "" : errors.project_name}</Form.Text>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className="justify-content-md-center"> 
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>{t("lable.project_details")} <span className="text-danger">*</span></Form.Label>
                                                <Form.Control as="textarea" rows="5"
                                                    name="project_details"
                                                    value={projectData ? projectData.project_details : ""}
                                                    onChange={handleChange}
                                                />
                                                <Form.Text className="text-danger">{(!errors) ? "" : errors.project_details}</Form.Text>
                                            </Form.Group>
                                        </Col> 
                                    </Row>
                                    <Row>
                                        <Col md={12} className="text-center mt-5 mb-5">
                                            <Button className="btn-default" onClick={handleSubmit}><i className="fa fa-arrow-circle-left"></i> Submit Project</Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Loader show={loading}/>
        </>
    )
}

export default AddProject;