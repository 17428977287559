import React, { useState, useEffect, useMemo } from "react";
import { Container, Row, Col, Image, Tab, Nav, Badge } from "react-bootstrap";
import { useParams } from "react-router-dom";
import * as Constants from "../../config/Constants";
import Moment from "moment";
import DataTable from "react-data-table-component";
import { UserContext } from "../context/user.context";
import Loader from "../header/Loader";
import Quotation from "./Quotation";
import File from "./File";
import AnalysisReport from "./AnalysisReport";
import Query from "./Query";
import StatusProgressBar from "./StatusProgressBar";

import company_logo from "../../assets/image/company_logo.png";
import "./project.scss";

function ProjectDetails(props) {
  const { token, userData } = React.useContext(UserContext);
  let { projectId } = useParams();
  const [quotationload, setQuotationload] = useState(true);
  const [fileLoad, setFileLoad] = useState(false);
  const [analysisload, setAnalysisload] = useState(false);
  const [queryload, setQueryload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [navShow, setNavShow] = useState(true);
  const [tab, setTab] = useState("Quotation");
  const [tabNavCol, setTabNavCol] = useState(2);
  const [tabContentCol, setTabContentCol] = useState(10);
  const [projectStatus, setProjectStatus] = useState({
    project_code: "",
    project_name: "",
    status: "",
    sub_status: "",
    quotationData: null,
    approvedQuotationData: null,
  });
  const [navHideShow, setNavHideShow] = useState({
    show: "show",
    a_style: "",
  });
  //dataTable
  const [eventData, setEventData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchProjectById(projectId);
    fetchEventsData(tab, 1);
  }, []);

  function fetchProjectById(projectId) {
    setLoading(true);
    fetch(Constants.API_PROJECT + projectId, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setProjectStatus({
          project_code: data.projectData.project_code,
          project_name: data.projectData.project_name,
          status: data.projectData.project_status,
          sub_status: data.projectData.project_sub_status,
          quotationData: data.quotationData,
          approvedQuotationData: data.approvedQuotationData,
        });
      });
  }

  const fetchEventsData = (tabVal, page, size = perPage) => {
    const data = {
      project_id: projectId,
      event_title: tabVal,
    };
    setLoading(true);
    fetch(Constants.API_EVENTLOG + "?page=" + page + "&per_page=" + size, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((resData) => {
        setLoading(false);
        setEventData(resData.eventData.data);
        setTotalRows(resData.eventData.total);
      });
  };

  const columns = useMemo(() => [
    {
      name: "Created Date",
      selector: "created_ts",
      sortable: true,
      cell: (row) => (
        <div>{Moment(row.created_ts).format("DD-MM-YYYY h:mm A")}</div>
      ),
    },
    {
      name: "User Name",
      selector: "first_name",
      sortable: true,
      cell: (row) => <div>{row.first_name + " " + row.last_name}</div>,
    },
    {
      name: "Event Type",
      selector: "event_type",
      sortable: true,
    },
    {
      name: "Title",
      selector: "event_title",
      sortable: true,
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
      wrap: true,
      width: "600px",
    },
  ]);

  const handlePageChange = (page) => {
    fetchEventsData(tab, page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    fetchEventsData(tab, page, newPerPage);
    setPerPage(newPerPage);
  };

  function handleShwoHide() {
    if (navShow) {
      setTabNavCol(1);
      setTabContentCol(11);
      setNavHideShow({
        show: "hide",
        a_style: "a-style",
      });
      setNavShow(false);
    } else {
      setTabNavCol(2);
      setTabContentCol(10);
      setNavHideShow({
        show: "show",
        a_style: "",
      });
      setNavShow(true);
    }
  }

  const handleTab = (tabVal, e) => {
    setTab(tabVal);
    fetchEventsData(tabVal, 1);
    if (tabVal === "Quotation") {
      setQuotationload(true);
      setFileLoad(false);
      setAnalysisload(false);
      setQueryload(false);
    } else if (tabVal === "File") {
      setQuotationload(false);
      setFileLoad(true);
      setAnalysisload(false);
      setQueryload(false);
    } else if (tabVal === "Analysis") {
      setQuotationload(false);
      setFileLoad(false);
      setAnalysisload(true);
      setQueryload(false);
    } else if (tabVal === "Query") {
      setQuotationload(false);
      setFileLoad(false);
      setAnalysisload(false);
      setQueryload(true);
    }
  };

  return (
    <>
      <Container className="mt-cust bread-crumb">
        <Row>
          <Col md={3} className="mb-3">
            {userData &&
            userData.company_logo !== null &&
            userData.company_logo !== "" ? (
              <Image
                src={Constants.API_BASE_URL + userData.company_logo}
                width="90"
                height="90"
              ></Image>
            ) : (
              <Image src={company_logo} width="90" height="90"></Image>
            )}
          </Col>
          <Col md={9} className="mb-3">
            <h2 className="text-center">
              View Project Details
              <a href="/project-list" className="btn btn-default float-right">
                <i className="fa fa-chevron-left"></i> Back to Project List
              </a>
              <a
                href="/add-project"
                className="btn btn-primary float-right mr-1"
              >
                <i className="fa fa-plus"></i> Add Project
              </a>
            </h2>
          </Col>
        </Row>
      </Container>

      <StatusProgressBar projectId={projectId} projectStatus={projectStatus} />

      <Container className="details-container">
        <Row className="mb-5">
          <Col md={12}>
            <Tab.Container id="left-tabs-example" defaultActiveKey="Quotation">
              <Row>
                <Col md={tabNavCol}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Quotation"
                        className={navHideShow["a_style"]}
                        onClick={(e) => handleTab("Quotation", e)}
                      >
                        <Badge className={navHideShow["show"]}>Quotation</Badge>{" "}
                        <i className="fa fa-inr"></i>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="File"
                        className={navHideShow["a_style"]}
                        onClick={(e) => handleTab("File", e)}
                      >
                        <Badge className={navHideShow["show"]}>File</Badge>{" "}
                        <i className="fa fa-file-text"></i>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Analysis"
                        className={navHideShow["a_style"]}
                        onClick={(e) => handleTab("Analysis", e)}
                      >
                        <Badge className={navHideShow["show"]}>
                          Analysis Report
                        </Badge>{" "}
                        <i className="fa fa-line-chart"></i>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="Query"
                        className={navHideShow["a_style"]}
                        onClick={(e) => handleTab("Query", e)}
                      >
                        <Badge className={navHideShow["show"]}>
                          Query / Notes
                        </Badge>{" "}
                        <i className="fa fa-commenting"></i>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col md={tabContentCol} className="details-body">
                  <Tab.Content>
                    <Tab.Pane eventKey="Quotation">
                      <Quotation
                        handleShwoHide={handleShwoHide}
                        quotationload={quotationload}
                        fetchEventsData={() => fetchEventsData("Quotation", 1)}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="File">
                      <File
                        handleShwoHide={handleShwoHide}
                        fileLoad={fileLoad}
                        fetchEventsData={() => fetchEventsData("File", 1)}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Analysis">
                      <AnalysisReport
                        handleShwoHide={handleShwoHide}
                        analysisload={analysisload}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="Query">
                      <Query
                        handleShwoHide={handleShwoHide}
                        queryload={queryload}
                        fetchEventsData={() => fetchEventsData("Query", 1)}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <Container className="event-container">
        <Row>
          <Col md={12}>
            <div className="header-co-1 event-table-header text-center">
              <i className="fa fa-history"></i>
            </div>
            <div className="header-co-11 event-table-header border-left border-white">
              Event Log
            </div>
          </Col>
          <Col md={12} className="list-view">
            <DataTable
              columns={columns}
              data={eventData}
              className="pr_datatable"
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationDefaultPage={currentPage}
              onChangeRowsPerPage={handlePerRowsChange}
              onChangePage={handlePageChange}
              noHeader={true}
              striped
              pointerOnHover
              highlightOnHover
            />
          </Col>
        </Row>
      </Container>
      <Loader show={loading} />
    </>
  );
}

export default ProjectDetails;
