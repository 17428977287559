import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Card,
  Form,
  Button,
} from "react-bootstrap";
import * as Constants from "../../config/Constants";
import Dialog from "react-bootstrap-dialog";
import { AlertContext } from "../context/Alert";
import Loader from "../header/Loader";
import { LocalizationContext } from "../context/localization/localization.context";

import lockIcon from "../../assets/image/lock-icon.png";
import sos_logo from "../../assets/image/sos-logo.png";
import accounting from "../../assets/image/accounting.jpg";

import "./authenticate.scss";

function Register(props) {
  const isEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const isNumber = /^[0-9\b]+$/;
  const { t, setLocale } = React.useContext(LocalizationContext);
  const { dialog } = React.useContext(AlertContext);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [sendOTP, setSendOTP] = useState(true);
  const [registerData, setRegisterData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    alt_contact: "",
    requirement: "",
    user_type: "customer",
    otp: "",
  });

  const handleValidation = (otp) => {
    let fields = registerData;
    let errors = {};
    let formIsValid = true;

    if (fields["first_name"] === "") {
      formIsValid = false;
      errors["first_name"] = t("errors.first_name");
    }
    if (fields["last_name"] === "") {
      formIsValid = false;
      errors["last_name"] = t("errors.last_name");
    }
    if (fields["email"] === "") {
      formIsValid = false;
      errors["email"] = t("errors.email");
    } else if (!isEmail.test(fields["email"])) {
      formIsValid = false;
      errors["email"] = t("errors.valid_email");
    }
    if (fields["mobile"] === "") {
      formIsValid = false;
      errors["mobile"] = t("errors.mobile_number");
    } else if (!isNumber.test(fields["mobile"])) {
      formIsValid = false;
      errors["mobile"] = t("errors.only_number");
    } else if (fields["mobile"].length < 10) {
      formIsValid = false;
      errors["mobile"] = t("errors.number_10_digit");
    }
    if (fields["alt_contact"] !== "") {
      if (!isNumber.test(fields["alt_contact"])) {
        formIsValid = false;
        errors["alt_contact"] = t("errors.only_number");
      } else if (fields["alt_contact"].length < 10) {
        formIsValid = false;
        errors["alt_contact"] = t("errors.number_10_digit");
      }
    }
    if (fields["requirement"] === "") {
      formIsValid = false;
      errors["requirement"] = t("errors.requirement");
    }
    // if (otp == "check_otp") {
    //   if (fields["otp"] === "") {
    //     formIsValid = false;
    //     errors["otp"] = "Please enter the otp number!";
    //   }
    // }
    setErrors(errors);
    return formIsValid;
  };

  const handleChange = (e) => {
    if (e.target.name === "mobile" || e.target.name === "alt_contact") {
      const onlyNumber = e.target.value.replace(/[^0-9]/g, "");
      const number = onlyNumber.replace(/(\d{3})(\d{3})(\d{4})/, "$1$2$3");
      setRegisterData({ ...registerData, [e.target.name]: number });
    } else {
      const { name, value } = e.target;
      setRegisterData({ ...registerData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (handleValidation("check_otp")) {
      // setLoading(true);
      const userData = {
        first_name: registerData.first_name,
        last_name: registerData.last_name,
        email: registerData.email,
        mobile_number: registerData.mobile,
        alternate_mobile_number: registerData.alt_contact,
        requirement: registerData.requirement,
        user_type: "customer",
        // otp: registerData.otp,
      };
      fetch(Constants.API_USER_REGISTER, {
        method: "POST",
        body: JSON.stringify(userData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          dialog.show({
            body: data.message,
            actions: [
              Dialog.OKAction(() => {
                if (data.status) {
                  props.history.push("/login");
                }
              }),
            ],
            bsSize: "small",
          });
        });
    }
  };

  const handleSendOTP = (e) => {
    e.preventDefault();
    if (handleValidation("send_otp")) {
      setLoading(true);
      const userData = {
        email: registerData.email,
        mobile_number: registerData.mobile,
        phone_number: registerData.mobile,
      };
      fetch(Constants.API_SEND_OTP, {
        method: "POST",
        body: JSON.stringify(userData),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setLoading(false);
          dialog.show({
            body: data.message,
            actions: [
              Dialog.OKAction(() => {
                if (data.status) {
                  setSendOTP(true);
                }
              }),
            ],
            bsSize: "small",
          });
        });
    }
  };

  return (
    <>
      <Container fluid className="container-register100">
        <Row>
          <Col md={6} className="col-align-right">
            <Row>
              <Col md={12} className="text-center mb-5">
                <a href={Constants.WEBSITE_BASE_URL}>
                  <Image
                    className="register-page-logo"
                    src={sos_logo}
                    alt="SOS Logo"
                  />
                </a>
              </Col>
              <Col md={12} className="clint-img-bt">
                <Image fluid className="img-bd" src={accounting} />
              </Col>
            </Row>
          </Col>
          <Col md={6} className="col-align-left">
            <Row>
              <Col md={12} className="mb-5">
                <h2 className="ml-115">
                  {t("title.New Customer Registration")}
                </h2>
                <a className="login100-form-lable" href="/login">
                  Back to Login
                </a>
              </Col>
              <Col md={12}>
                <hr />
                <Card className="card-view">
                  <Card.Body>
                    {/* <Button onClick={() => setLocale("jp")}>Change To eg</Button> */}
                    <Form>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          {t("lable.first_name")}{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            type="text"
                            placeholder={t("lable.first_name")}
                            name="first_name"
                            onChange={handleChange}
                          />
                          <Form.Text className="text-danger">
                            {!errors ? "" : errors.first_name}
                          </Form.Text>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          {t("lable.last_name")}{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            type="text"
                            placeholder={t("lable.last_name")}
                            name="last_name"
                            onChange={handleChange}
                          />
                          <Form.Text className="text-danger">
                            {!errors ? "" : errors.last_name}
                          </Form.Text>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          {t("lable.email")}:{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            type="email"
                            placeholder={t("lable.email")}
                            name="email"
                            onChange={handleChange}
                          />
                          <Form.Text className="text-danger">
                            {!errors ? "" : errors.email}
                          </Form.Text>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          {t("lable.mobile_number")}:{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            type="tel"
                            placeholder={t("lable.mobile_number")}
                            name="mobile"
                            value={registerData ? registerData.mobile : ""}
                            maxLength="10"
                            onChange={handleChange}
                          />
                          <Form.Text className="text-danger">
                            {!errors ? "" : errors.mobile}
                          </Form.Text>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Form.Label column sm="4">
                          {t("lable.alternate_contact_number")}:{" "}
                        </Form.Label>
                        <Col sm={8}>
                          <Form.Control
                            type="tel"
                            placeholder={t("lable.alternate_contact_number")}
                            name="alt_contact"
                            value={registerData ? registerData.alt_contact : ""}
                            maxLength="10"
                            onChange={handleChange}
                          />
                          <Form.Text className="text-danger">
                            {!errors ? "" : errors.alt_contact}
                          </Form.Text>
                        </Col>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>
                          {t("lable.requirement")}:{" "}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows="3"
                          name="requirement"
                          onChange={handleChange}
                        />
                        <Form.Text className="text-danger">
                          {!errors ? "" : errors.requirement}
                        </Form.Text>
                      </Form.Group>
                      <Row className="row">
                        <Col md={6}>
                          {sendOTP !== true ? (
                            <>
                              <Image
                                src={lockIcon}
                                style={{ width: "55px", float: "left" }}
                              />
                              <Form.Control
                                type="text"
                                size="lg"
                                className="otp-textbox textbox-red"
                                placeholder={t("lable.otp")}
                                name="otp"
                                onChange={handleChange}
                              />
                              <Form.Text className="text-danger">
                                {!errors ? "" : errors.otp}
                              </Form.Text>
                            </>
                          ) : (
                            ""
                          )}
                        </Col>
                        <Col md={6}>
                          {sendOTP === true ? (
                            <>
                              <button
                                style={{ marginTop: "14px" }}
                                type="submit"
                                onClick={handleSubmit}
                                className="btn-custom ml-2  float-right"
                              >
                                {" "}
                                {t("button.register")}{" "}
                              </button>
                              {/* <button
                                style={{ marginTop: "14px" }}
                                type="submit"
                                onClick={handleSendOTP}
                                className="btn btn-secondary float-right"
                              >
                                {" "}
                                {t("button.resend_otp")}{" "}
                              </button> */}
                            </>
                          ) : (
                            <button
                              style={{ marginTop: "14px" }}
                              type="submit"
                              onClick={handleSendOTP}
                              className="btn-custom float-right"
                            >
                              {" "}
                              {t("button.send_otp")}{" "}
                            </button>
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
      <Loader show={loading} />
    </>
  );
}

export default Register;
