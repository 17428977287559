import React, { useEffect, useMemo, useState, useCallback} from 'react';
import { Row, Col, Card, DropdownButton, Dropdown, Button, Modal } from "react-bootstrap";
import { Link, useParams } from 'react-router-dom';
import Moment from 'moment';
import DataTable from "react-data-table-component";
import Dialog from "react-bootstrap-dialog";
import { AlertContext } from "../context/Alert";
import Loader from '../header/Loader';
import CommonModal from '../header/CommonModal';
import  * as Constants from "../../config/Constants";
import { UserContext } from "../context/user.context";

function Quotation(props) {
    const { token } = React.useContext(UserContext);
    const { dialog } = React.useContext(AlertContext);
    let { projectId } = useParams();
    const [loading, setLoading] = useState(false);
    const [approvedQuotationData, setApprovedQuotationData] = useState(null);
    //dataTable
    const [tableData, setTableData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState({
        title: "",
        data: ""
    });

    useEffect(() => {
        if (props.quotationload) {
            fetchQuotation(1);
        }
    }, [props.quotationload]);

    const fetchQuotation = (page, size = perPage) => {
        setLoading(true);
        fetch(Constants.API_GET_QUOTATION_BY_PROJECT + projectId + "?page=" + page + "&per_page=" + size , { 
            method: "GET",
            headers: { 
                "Authorization": "Bearer " + token
            }
        }) 
        .then(response => response.json())
        .then(resData => {
                setLoading(false);
                setTableData(resData.quotationData.data);
                setTotalRows(resData.quotationData.total);
                setApprovedQuotationData(resData.approvedQuotationData);
            }
        );
    };

    const changeQuotationStatus = (quotation_id, postData) => {
        setLoading(true);
        fetch(Constants.API_QUOTATION_APPROVE_REJECT + quotation_id, { 
            method: "PUT",
            body: JSON.stringify(postData),
            headers: { 
                "Authorization": "Bearer " + token
            }
        }) 
        .then(response => response.json())
        .then(
            data => {
                setLoading(false);
                dialog.show({
                    body: data.message,
                    bsSize: 'small',
                    actions: [
                        Dialog.OKAction(() => {
                            fetchQuotation(1);
                            return props.fetchEventsData();
                        })
                    ],
                });
            }
        );
    }

    const handleApproveRejectQuotation = useCallback(
        row => async (e) => {
            const quotation_id = row.quotation_id;
            const quotationStatus = (e == 1) ? "Approved" : "Rejected" ;
            dialog.show({
                body: `Are you sure you want to ${quotationStatus} the quotation?`,
                bsSize: 'small',
                actions: [
                    Dialog.CancelAction(),
                    Dialog.OKAction(() => {
                        if (e == 1) {
                            const postData = {
                                status: e
                            }
                            changeQuotationStatus(quotation_id, postData);
                        } else {
                            dialog.show({
                                body: "Reject Reason",
                                bsSize: 'small',
                                prompt: Dialog.TextPrompt({initialValue: '', placeholder: '', required: true}),
                                actions: [
                                    Dialog.CancelAction(),
                                    Dialog.OKAction((dialog) => {
                                        const postData = {
                                            status: e,
                                            rejected_comments: dialog.value
                                        }
                                        changeQuotationStatus(quotation_id, postData);
                                    })
                                ],
                            });
                        }
                    })
                ]
            });
        }
    );
    const showComments = useCallback (
        row => async () => {
            dialog.show({
                body: row.comment,
                bsSize: 'small',
                actions: [
                    Dialog.OKAction()
                ],
            });
        }
    );
    const showRejectComments = useCallback (
        row => async () => {
            dialog.show({
                body: row.rejected_comments,
                bsSize: 'small',
                actions: [
                    Dialog.OKAction()
                ],
            });
        }
    );

    const columns = useMemo(
        () => [
            {
                selector: "quotation_revision",
                sortable: true,
                width: '210px',
            }, {
                selector: "amount",
                sortable: true,
                width: '100px',
                cell: row => <div>
                        <i className="fa fa-inr"></i>
                        {"\u00a0" + parseFloat(row.amount).toFixed(2)} 
                    </div>
            },{
                cell: row => <div>
                        {(row.comment !== null && row.comment !== "") ? 
                            <>
                                <b>Comment : </b>{row.comment.slice(0, 60)} 
                                <a href="javascript:void(0);" onClick={showComments(row)}>....</a> <br/>
                            </>
                        : 
                            ""
                        }
                        {(row.status == 2 && row.rejected_comments !== null && row.rejected_comments !== "") ? 
                            <>
                                <b>Reject Comment : </b>{row.rejected_comments.slice(0, 60)} 
                                <a href="javascript:void(0);" onClick={showRejectComments(row)}>....</a>
                            </>
                        : 
                            ""
                        }
                    </div>
            }, {
                name: "Created Date",
                selector: "created_ts",
                sortable: true,
                cell: row => <div>{Moment(row.created_ts).format('DD-MM-YYYY h:mm A')}</div>,
                width: '210px',
            }, {
                cell: row => <div> <a href={Constants.API_BASE_URL + row.quotation_file} target="_blank" className="btn btn-success btn-sm"><i className="fa fa-download"></i></a></div>,
                width: '50px',
            }, {
                cell: row => <div className="w-100">
                        {(row.status == 0) ? 
                            <DropdownButton onSelect={handleApproveRejectQuotation(row)} className="w-100" size="sm" id="dropdown-basic-button" title="Pending">
                                <Dropdown.Item eventKey="1">Approved</Dropdown.Item>
                                <Dropdown.Item eventKey="2">Rejected</Dropdown.Item>
                            </DropdownButton>
                        : 
                            "" 
                        }
                        {(row.status == 1) ? <label className=" mt-2 p-2 text-center rounded bg-success text-white w-100">Approved</label> : "" }
                        {(row.status == 2) ? <label className=" mt-2 p-2 text-center rounded bg-secondary text-white w-100">Rejected</label> : "" }
                    </div>,
                width: '210px',
            }
        ]
    );

    const handlePageChange = page => {
        fetchQuotation(page);
        setCurrentPage(page);
    };
    
    const handlePerRowsChange = async (newPerPage, page) => {
        fetchQuotation(page, newPerPage);
        setPerPage(newPerPage);
    };

    function showPaymentDetails(qoutation_id) {
        setLoading(true);
        fetch(Constants.API_GET_PAYMENT_DETAILS + qoutation_id , { 
            method: "GET",
            headers: { 
                "Authorization": "Bearer " + token
            }
        }) 
        .then(response => response.json())
        .then(resData => {
                setLoading(false);
                setModalData({
                    title: "Payment Details",
                    data: resData.data
                })
                setShowModal(true);
            }
        );
    }

    return (
        <>
            <Card>
                <Card.Header>
                    <h2>
                        <Link onClick={props.handleShwoHide} className="text-success"><i className="fa fa-bars"></i> Quotation</Link>
                        <Button className="btn-info ml-2 float-right" onClick={() => {fetchQuotation(1); return props.fetchEventsData()}}><i className="fa fa-refresh"></i> Refresh</Button>
                        {
                            (approvedQuotationData) ?
                                <>
                                    <span className="staus-text">Payment {(approvedQuotationData.payment_status == 0) ? "Pending" : "Paid"}</span>
                                    <span className="amount-text"><i className="fa fa-inr"></i> { parseFloat(approvedQuotationData.amount).toFixed(2)}</span>
                                </>
                            :
                                ""
                        }
                    </h2>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={4}>
                            <Card className="text-center mb-3">
                                <Card.Header>
                                    <Card.Text>Quotation Status</Card.Text>
                                </Card.Header>
                                <Card.Body>
                                    {
                                        (approvedQuotationData) ?
                                            (approvedQuotationData.quotation_file && approvedQuotationData.quotation_file !== "") ?
                                                <Card.Text className="p-1 text-success"> <a className="p-1 text-success" href={Constants.API_BASE_URL + approvedQuotationData.quotation_file} target="_blank" title="Download quotation file"><i className="fa fa-download"></i> Approved </a></Card.Text>
                                            :
                                                <Card.Text className="p-1 text-success"> Approved </Card.Text>
                                        :
                                            (tableData.length > 0) ?
                                                <Card.Text className="p-1 "> Pending </Card.Text>
                                            :
                                                <Card.Text className="p-1 "> -- </Card.Text>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="text-center mb-3">
                                <Card.Header>
                                    <Card.Text>Payment</Card.Text>
                                </Card.Header>
                                <Card.Body>
                                    {
                                        (approvedQuotationData) ?
                                            (approvedQuotationData.payment_status === "0") ? 
                                                <Link to={ "/payment/" + projectId } className="btn btn-sm btn-primary">Make Payment</Link> 
                                            : 
                                            <>
                                                <Card.Text className="p-1"> <a className="text-success" href="jacasrcipt:void(0);" onClick={() => showPaymentDetails(approvedQuotationData.quotation_id)}>Paid</a> </Card.Text>
                                            </>
                                        :
                                            <Card.Text className="p-1"> -- </Card.Text>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={4}>
                            <Card className="text-center mb-3">
                                <Card.Header>
                                    <Card.Text>Download</Card.Text>
                                </Card.Header>
                                <Card.Body>
                                    {
                                        (approvedQuotationData) ?
                                            (approvedQuotationData.payment_status === "0") ? 
                                                <Card.Text className="p-1"> No Reciept </Card.Text> 
                                            : 
                                            (approvedQuotationData.payment_receipt_file && approvedQuotationData.payment_receipt_file !== "") ?
                                                    <Card.Text className="p-1"><a className="text-success" href={Constants.API_BASE_URL + approvedQuotationData.payment_receipt_file} target="_blank"><i className="fa fa-download"></i> Payment Reciept</a></Card.Text>
                                            :
                                                <Card.Text className="p-1"> No Reciept </Card.Text>
                                        :
                                            <Card.Text className="p-1"> No Reciept </Card.Text>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
                <hr style={{marginBottom: "0px"}}/>
                <DataTable
                    columns={columns}
                    data={tableData}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    noHeader={true}
                    noTableHead={true}
                    highlightOnHover
                />
            </Card>
            <CommonModal data={modalData} show={showModal} onHide={() => setShowModal(false)}/>
            <Loader show={loading}/>
        </>
    );
}

export default Quotation;