import React, { useState, useEffect } from "react";
import { Container, Row, Col, ProgressBar, Button } from "react-bootstrap";
import {
  getProjectStatusArr,
  getProjectPaymentSubStatusArr,
} from "../../config/config";

function StatusProgressBar(props) {
  const statusArr = getProjectStatusArr();
  const subStatusArr = getProjectPaymentSubStatusArr();
  const [projectCode, setProjectCode] = useState(null);
  const [subStatus, setSubStatus] = useState(null);
  const [progress, setProgress] = useState({
    progress_value: 0,
    circle: {
      one: "one primary-color",
      two: "two no-color",
      three: "three no-color",
      four: "four no-color",
      five: "five no-color",
    },
  });
  const [progressColor, setProgressColor] = useState({
    v1: "",
    v2: "",
    v3: "",
    v4: "",
  });

  useEffect(() => {
    setProgressBar(props.projectId, props.projectStatus);
  }, [props.projectId, props.projectStatus]);

  function setProgressBar(projectId, projectStatus) {
    setProjectCode(
      projectStatus.project_code + " - " + projectStatus.project_name
    );
    let sub_status =
      projectStatus.sub_status && projectStatus.sub_status !== ""
        ? projectStatus.sub_status
        : null;
    setSubStatus(sub_status);
    let progressParcent = 0;
    if (projectStatus.status === "1") {
      if (
        sub_status !== "a" &&
        sub_status !== "b" &&
        sub_status !== "c" &&
        sub_status !== "e" &&
        sub_status !== "f" &&
        sub_status !== "g"
      ) {
        progressParcent = 0;
        setProgress({
          progress_value: 0,
          circle: {
            one: "one primary-color",
            two: "two no-color",
            three: "three no-color",
            four: "four no-color",
            five: "five no-color",
          },
        });
      } else {
        progressParcent = 12.5;
        setProgress({
          progress_value: 12.5,
          circle: {
            one: "one primary-color",
            two: "two no-color",
            three: "three no-color",
            four: "four no-color",
            five: "five no-color",
          },
        });
      }
    } else if (projectStatus.status === "2") {
      if (sub_status !== "a" && sub_status !== "b" && sub_status !== "c") {
        progressParcent = 25;
        setProgress({
          progress_value: 25,
          circle: {
            one: "one primary-color",
            two: "two primary-color",
            three: "three no-color",
            four: "four no-color",
            five: "five no-color",
          },
        });
      } else {
        progressParcent = 37.5;
        setProgress({
          progress_value: 37.5,
          circle: {
            one: "one primary-color",
            two: "two primary-color",
            three: "three no-color",
            four: "four no-color",
            five: "five no-color",
          },
        });
      }
    } else if (projectStatus.status === "3") {
      if (
        sub_status !== "a" &&
        sub_status !== "b" &&
        sub_status !== "c" &&
        sub_status !== "d" &&
        sub_status !== "h"
      ) {
        progressParcent = 50;
        setProgress({
          progress_value: 50,
          circle: {
            one: "one primary-color",
            two: "two primary-color",
            three: "three primary-color",
            four: "four no-color",
            five: "five no-color",
          },
        });
      } else {
        progressParcent = 62.5;
        setProgress({
          progress_value: 62.5,
          circle: {
            one: "one primary-color",
            two: "two primary-color",
            three: "three primary-color",
            four: "four no-color",
            five: "five no-color",
          },
        });
      }
    } else if (projectStatus.status === "4") {
      if (sub_status !== "a" && sub_status !== "b" && sub_status !== "c") {
        progressParcent = 76;
        setProgress({
          progress_value: 76,
          circle: {
            one: "one primary-color",
            two: "two primary-color",
            three: "three primary-color",
            four: "four primary-color",
            five: "five no-color",
          },
        });
      } else {
        progressParcent = 87.5;
        setProgress({
          progress_value: 87.5,
          circle: {
            one: "one primary-color",
            two: "two primary-color",
            three: "three primary-color",
            four: "four primary-color",
            five: "five no-color",
          },
        });
      }
    } else if (projectStatus.status === "5") {
      progressParcent = 100;
      setProgress({
        progress_value: 100,
        circle: {
          one: "one primary-color",
          two: "two primary-color",
          three: "three primary-color",
          four: "four primary-color",
          five: "five primary-color",
        },
      });
    }
    const color1 =
      sub_status && projectStatus.status == "1"
        ? subStatusArr[sub_status]["color"]
        : "outline-secondary";
    const color2 =
      sub_status && projectStatus.status == "2"
        ? subStatusArr[sub_status]["color"]
        : "outline-secondary";
    const color3 =
      sub_status && projectStatus.status == "3"
        ? subStatusArr[sub_status]["color"]
        : "outline-secondary";
    const color4 =
      sub_status && projectStatus.status == "4"
        ? subStatusArr[sub_status]["color"]
        : "outline-secondary";

    setProgressColor({
      v1: progressParcent >= 25 ? "success" : color1,
      v2: progressParcent >= 50 ? "success" : color2,
      v3: progressParcent >= 76 ? "success" : color3,
      v4: progressParcent >= 100 ? "success" : color4,
    });
  }

  return (
    <Container className="progress-container">
      <Row className="justify-content-md-center">
        <Col md={10} className="project-code text-center">
          <samp>{projectCode ? projectCode : ""}</samp>
        </Col>
        <Col md={10} className="mt-5">
          <ProgressBar style={{ height: "3px" }} isChild={false}>
            <div className={progress.circle.one}>
              <Button className="btn-sm btn-success">
                {statusArr[1]["status"]}
              </Button>
            </div>
            <div className={progress.circle.two}>
              <Button
                className="btn-sm btn-primary"
                variant={progressColor["v1"]}
              >
                {statusArr[2]["status"]}
                <small>
                  {subStatus && props.projectStatus.status === "1"
                    ? " (" + subStatusArr[subStatus]["display_name"] + ")"
                    : ""}
                </small>
              </Button>
            </div>
            <div className={progress.circle.three}>
              <Button className="btn-sm" variant={progressColor["v2"]}>
                {statusArr[3]["status"]}
                <small>
                  {subStatus && props.projectStatus.status === "2"
                    ? " (" + subStatusArr[subStatus]["display_name"] + ")"
                    : ""}
                </small>
              </Button>
            </div>
            <div className={progress.circle.four}>
              <Button className="btn-sm" variant={progressColor["v3"]}>
                {statusArr[4]["status"]}
                <small>
                  {subStatus && props.projectStatus.status === "3"
                    ? " (" + subStatusArr[subStatus]["display_name"] + ")"
                    : ""}
                </small>
              </Button>
            </div>
            <div className={progress.circle.five}>
              <Button className="btn-sm" variant={progressColor["v4"]}>
                {statusArr[5]["status"]}
                <small>
                  {subStatus && props.projectStatus.status === "4"
                    ? " (" + subStatusArr[subStatus]["display_name"] + ")"
                    : ""}
                </small>
              </Button>
            </div>
            <ProgressBar now={progress["progress_value"]}></ProgressBar>
          </ProgressBar>
        </Col>
      </Row>
    </Container>
  );
}

export default StatusProgressBar;
