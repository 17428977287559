import React, { useEffect, useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import * as Constants from "../../config/Constants";
import Loader from "../header/Loader";
import { AlertContext } from "../context/Alert";
import { UserContext } from "../context/user.context";

import company_logo from "../../assets/image/company_logo.png";
import "./dashboard.scss";
import Dialog from "react-bootstrap-dialog";

function Dashboard(props) {
  const { userData, token, userId } = React.useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [notificationCount, setNotificationCount] = useState("0");
  const [notificationData, setNotificationData] = useState([]);
  const { dialog } = React.useContext(AlertContext);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = () => {
    setLoading(true);
    fetch(Constants.API_GET_DASHBOARD_DATA + userId, {
      method: "GET",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data.status) {
          setNotificationData(data.dashboard.notificationData);
          setNotificationCount(data.dashboard.notificationCount);
        }
      });
  };

  const addProject = () => {
    if (
      userData &&
      userData.company_id !== null &&
      userData.company_id !== ""
    ) {
      props.history.push("/add-project");
    } else {
      dialog.show({
        body: "Complete your Business Profile first",
        actions: [
          Dialog.OKAction(() => {
            props.history.push("/business-details");
          }),
        ],
        bsSize: "small",
      });
    }
  };

  return (
    <>
      <Container className="mt-cust mb-5 notification">
        <a href="/notification">
          <Row className="row-border">
            <Col md={1} className="text-center m-auto">
              <i
                className="fa fa-bell-o"
                style={{ fontSize: "50Px", color: "#E74F2B" }}
                aria-hidden="true"
              ></i>
            </Col>
            <Col md={11}>
              <ul className="notification-ul">
                {notificationData.length > 0 ? (
                  notificationData.map((row, i) => (
                    <li className="text-primary" key={i}>
                      {row.description}
                    </li>
                  ))
                ) : (
                  <li className="text-primary">No Notification</li>
                )}
              </ul>
            </Col>
          </Row>
        </a>
      </Container>
      <Container className="box">
        <Row>
          <Col className="text-center m-auto">
            {userData &&
            userData.company_logo !== null &&
            userData.company_logo !== "" ? (
              <Image
                src={Constants.API_BASE_URL + userData.company_logo}
                className="mb-3"
              ></Image>
            ) : (
              <Image src={company_logo} className="mb-3"></Image>
            )}
          </Col>
          <Col className="text-center">
            <a href="/project-list">
              <div className="small-box mb-3">
                <p>
                  <i className="fa fa-eye" aria-hidden="true"></i> <br /> View
                  Project Details
                </p>
              </div>
            </a>
          </Col>
          <Col className="text-center">
            <a onClick={(e) => addProject()}>
              <div className="small-box mb-3">
                <i className="fa fa-plus-circle" aria-hidden="true"></i> <br />{" "}
                Add Project
              </div>
            </a>
          </Col>
          <Col className="text-center">
            <a href="">
              <div className="small-box mb-3">
                <p>
                  <i className="fa fa-folder" aria-hidden="true"></i> <br /> Add
                  Files
                </p>
              </div>
            </a>
          </Col>
          <Col className="text-center">
            <a href="/notification">
              <div className="small-box mb-3">
                <p>
                  <i className="fa fa-bell-o" aria-hidden="true"></i>
                  <span className="badge navbar-badge">
                    <b>{notificationCount}</b>
                  </span>
                  <br /> Alerts / Notification
                </p>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
      <Loader show={loading} />
    </>
  );
}

export default Dashboard;
