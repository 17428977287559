import React from "react";
import "../../index.scss"

function Loader ({show}) {
    const cls = (show) ? "loader loader-show" : "loader-hide";
    return(
        <>
            <div className={cls}>
                <div className="loader__inner">
                    <div className="loader__content"><span className="spinner"></span></div>
                </div>
            </div>
        </>
    )
}

export default Loader;