import React from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "../context/user.context";

function PrivateRoute({ component: Component, ...rest }) {
    const { token } = React.useContext(UserContext);

    return (
        <Route
            {...rest}
            render={(props) => token ? <Component {...props} /> : <Redirect to={"/login"} />}
        />
    );
}

export default PrivateRoute;