import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Col, Image } from "react-bootstrap";
import { UserContext } from "../context/user.context";
import  * as Constants from "../../config/Constants";
import Moment from 'moment';
import DataTable from "react-data-table-component";
import Loader from '../header/Loader';
import company_logo from "../../assets/image/company_logo.png";

function Notification() {
    const { token, userData, userId } = React.useContext(UserContext);
    const [loading, setLoading] = useState(false);
    //dataTable
    const [notificationData, setNotificationData] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        fetchNotifecationData(1)
    }, []);

    const fetchNotifecationData = (page, size = perPage) => {
        setLoading(true);
        fetch(Constants.API_GET_ALL_NOTIFICATION_DATA + userId + "?page=" + page + "&per_page=" + size, { 
            method: "GET",
            headers: { 
                "Authorization": "Bearer " + token
            }
        }) 
        .then(response => response.json())
        .then(resData => {
                setLoading(false);
                setNotificationData(resData.resulte.data);
                setTotalRows(resData.resulte.total);
            }
        );
    }

    const columns = useMemo(
        () => [
            {
                name: "Created Date",
                selector: "created_ts",
                sortable: true,
                cell: row => <div>{Moment(row.created_ts).format('DD-MM-YYYY h:mm A')}</div>,
            }, {
                name: "Created User Name",
                selector: "created_user_first_name",
                sortable: true,
                cell: row => <div>{row.created_user_first_name + " " + row.created_user_last_name}</div>,
            }, {
                name: "Title",
                selector: "title",
                sortable: true
            }, {
                name: "Description",
                selector: "description",
                sortable: true,
                wrap: true,
                width: '600px'
            }, {
                name: "Action",
                cell: row => <a href={`project-details/${row.project_id}`} className="btn btn-info"> View</a>,
                width: '100px',
            }
        ]
    );

    const conditionalRowStyles = [
        {
          when: row => row.read === '0',
          style: {
            backgroundColor: 'rgb(237 237 237 / 90%)',
            color: '#000',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }
      ];

    const handlePageChange = page => {
        fetchNotifecationData(page);
        setCurrentPage(page);
    };
    
    const handlePerRowsChange = async (newPerPage, page) => {
        fetchNotifecationData(page, newPerPage);
        setPerPage(newPerPage);
    };

    return (
        <>
        <Container className="mt-cust bread-crumb">
                <Row>
                    <Col md={12}>
                        <Row>
                            <Col md={3} className="mb-3">
                                {
                                    (userData && userData.company_logo !== null && userData.company_logo !== "") ?
                                        <Image src={Constants.API_BASE_URL + userData.company_logo} width="90" height="90"></Image> 
                                    : 
                                        <Image src={company_logo} width="90" height="90"></Image> 
                                }
                            </Col>
                            <Col md={6} className="mb-3 text-center">
                                <h2>Alerts / Notification</h2>
                            </Col>
                            <Col md={3} className="mb-3">
                                <a href="/" className="btn btn-default float-right">
                                    <i className="fa fa-chevron-left"></i> Back to Dashboard
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
            <Container className="mt-5 mb-5 notification">
                <Row>
                    <Col md={12}>
                        <DataTable
                            columns={columns}
                            data={notificationData}
                            className="pr_datatable"
                            pagination
                            paginationServer
                            paginationTotalRows={totalRows}
                            paginationDefaultPage={currentPage}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            conditionalRowStyles={conditionalRowStyles}
                        />
                    </Col>
                </Row>
            </Container>
            <Loader show={loading}/>
        </>
    )
}

export default Notification;