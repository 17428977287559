import React from "react";
import logo from "../../assets/image/sos-logo.png";
import bg from "../../assets/image/bg.jpg";

import './error.scss';

function PageNotFound() {
    return (
        <>
            <div className="notfound-div">
                <div className="notfound-bg" style={{backgroundImage: `url(${bg})`}}></div>
                <div className="notfound">
                    <div className="notfound-logo">
                        <img src={logo}/>
                    </div>
                    <div className="notfound-404">
                        <h1>404</h1>
                    </div>
                    <h2>we are sorry, but the page you requested was not found</h2>
                    <a href="/login" className="home-btn">Go Home</a>
                </div>
            </div>
        </>
    )
}

export default PageNotFound;