import React, { useEffect, useState } from "react";
import { Row, Col, Card, Image, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import * as Constants from "../../config/Constants";
import { UserContext } from "../context/user.context";

import dounload_report from "../../assets/image/report.png";

function AnalysisReport(props) {
  const { token } = React.useContext(UserContext);
  let { projectId } = useParams();
  const [analysisData, setAnalysisData] = useState(null);

  useEffect(() => {
    if (props.analysisload) {
      fetchAnalysisReport();
    }
  }, [props.analysisload]);

  const fetchAnalysisReport = () => {
    fetch(Constants.API_GET_ANALYSIS_BY_PROJECT + projectId, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => response.json())
      .then((resData) => {
        setAnalysisData(resData.analysisData);
      });
  };

  return (
    <Card>
      <Card.Header>
        <h2>
          <Link onClick={props.handleShwoHide} className="text-success">
            <i className="fa fa-bars"></i> Analysis Report
          </Link>
          <Button
            className="btn-info ml-2 float-right"
            onClick={() => fetchAnalysisReport(1)}
          >
            <i className="fa fa-refresh"></i> Refresh
          </Button>
        </h2>
      </Card.Header>
      <Card.Body>
        <Row>
          {analysisData ? (
            analysisData.payment_status === "1" ||
            analysisData.allow_report_analysis === "1" ? (
              <>
                <Col md={3} className="text-center">
                  <a
                    href={Constants.API_BASE_URL + analysisData.upload_file}
                    target="_blank"
                    download
                  >
                    <Image
                      src={dounload_report}
                      style={{ maxWidth: "45%" }}
                      title="Download Report"
                    />
                    <br />
                    Download Report
                  </a>
                </Col>
                <Col md={9}>
                  <Card style={{ border: "none" }}>
                    <Card.Body>
                      <Card.Title>{analysisData.title}</Card.Title>
                      <p>{analysisData.comments}</p>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            ) : (
              <>
                <Col md={3} className="text-center">
                  <Image
                    src={dounload_report}
                    style={{ maxWidth: "45%" }}
                    title="Download Report"
                  />
                  <br />
                  Download Report
                </Col>
                <Col md={9}>
                  <Card style={{ border: "none" }}>
                    <Card.Body>
                      <p className="mt-4">
                        Analysis Report not generated. Contact SOS admin.
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            )
          ) : (
            <>
              <Col md={3} className="text-center">
                <Image
                  src={dounload_report}
                  style={{ maxWidth: "45%" }}
                  title="Download Report"
                />
                <br />
                Download Report
              </Col>
              <Col md={9}>
                <Card style={{ border: "none" }}>
                  <Card.Body>
                    <p className="mt-4">
                      Analysis Report not generated. Contact SOS admin.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
}

export default AnalysisReport;
