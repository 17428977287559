import React from "react";
import Dialog from 'react-bootstrap-dialog'

const {createContext, useState} = React;

export const AlertContext = createContext();

export function AlertProvider({children}) {
  const [dialog, setDialog] = useState();

  return (
    <AlertContext.Provider value={{
      dialog
    }}>
      <Dialog ref={(component) => { setDialog(component) }} />
      {children}
    </AlertContext.Provider>
  );
}