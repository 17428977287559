import React from "react";
import { useHistory, Link } from "react-router-dom";
import { Navbar, Nav, Image, Badge, Button } from "react-bootstrap";
import { UserContext } from "../context/user.context";

import sos_logo from "../../assets/image/sos-logo.png";
import home_icon from "../../assets/image/home-icon.png";
import home_icon_active from "../../assets/image/home-icon-active.png";
import user_icon from "../../assets/image/user-icon.png";
import user_icon_active from "../../assets/image/user-icon-active.png";
import user_approved from "../../assets/image/user-approved.png";

function Menu({children}) {
    const { userData } = React.useContext(UserContext);
    let history = useHistory();

    return (
        <>
            <Navbar expand="lg" className="bg-white shadow fixed-top">
                <Navbar.Brand href="/">
                    <Image
                        src={sos_logo}
                        className="d-inline-block align-top"
                        style={{width: "262px"}}
                    />
                </Navbar.Brand>
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto mr-auto">
                        <Badge>
                            <strong>Welcome </strong> 
                            <small>{(userData !== null) ? userData.first_name + " " + userData.last_name : "Sandra Adams"}</small>
                            <br/><br/>
                            {
                                (userData !== null && userData.company_name !== "") ?
                                    <small style={{fontSize: "15px", color: "#000"}}>{userData.company_name}</small>
                                :
                                    ""
                            }
                        </Badge>
                    </Nav>
                    <Nav.Link className="text-center" style={{cursor: "default"}}>
                        <img src={user_approved} width="40" height="40" alt=""/>
                        <br/> <small className="text-success" style={{fontSize: "12px"}}>Verified Customer</small>
                    </Nav.Link>
                    <Nav.Link href="/">
                        {
                            (history.location.pathname === "/") ?
                                <img src={home_icon_active} width="40" height="40" alt=""/>
                            :
                                <img src={home_icon} width="40" height="40" alt=""/>
                        }
                    </Nav.Link>
                    <Nav.Link href="/personal-details">
                        {
                            (history.location.pathname === "/personal-details" || history.location.pathname === "/business-details" || history.location.pathname === "/change-password") ?
                                <img src={user_icon_active} width="40" height="40" style={{borderRadius: "50%"}} title="Profile"/>
                            :
                                <img src={user_icon} width="40" height="40" style={{borderRadius: "50%"}} title="Profile"/>
                        }   
                    </Nav.Link>
                    <Link to="/login" className="btn btn-primary ml-3">Logout</Link>
                </Navbar.Collapse>
            </Navbar>
            {children}

        </>
    )
}

export default Menu;