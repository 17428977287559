import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Image, Form, ListGroup, Card, Button, InputGroup } from "react-bootstrap";
import Dialog from "react-bootstrap-dialog";
import { AlertContext } from "../context/Alert";
import  * as Constants from "../../config/Constants";
import { UserContext } from "../context/user.context";
import Loader from '../header/Loader';
import profile_default from "../../assets/image/profile_default.png";
import { LocalizationContext } from "../context/localization/localization.context";

import "./profile.scss"
const regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
function BusinessDetails() {
    const { token, userId, userData, removeLocalStorage } = React.useContext(UserContext);
    const { t } = React.useContext(LocalizationContext);
    const { dialog } = React.useContext(AlertContext);
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(null);
    const [fieldsData, setFieldsData] = useState({
        user_id: userId,
        current_password: "",
        new_password: "",
        confirm_password: ""
    });
    const [passwordShow, setPasswordShow] = useState({
        hideShow: "hide",
        type: "password"
    });
    const [confPasswordShow, setConfPasswordShow] = useState({
        hideShow: "hide",
        type: "password"
    });

    function handleChange(e) {
        const { name, value } = e.target;
        setFieldsData({ ...fieldsData, [name]: value });

        let errors = {};
        if (e.target.value !== "" && e.target.name === "new_password") {
            if (!regularExpression.test(e.target.value)) {
                errors["new_password"] = t("errors.password_must_be_8_char");
            }
        }
        setErrors(errors);
    }

    function handleValidation(e) {
        let fields = fieldsData;
        let errors = {};
        let formIsValid = true;
        if (fields["current_password"] === "") {
            formIsValid = false;
            errors["current_password"] = t("errors.current_password");
        }
        if (fields["new_password"] === "") {
            formIsValid = false;
            errors["new_password"] =  t("errors.password");
        } else if (!regularExpression.test(fields["new_password"])) {
            formIsValid = false;
            errors["new_password"] =  t("errors.password_must_be_8_char");
        }
        if (fields["confirm_password"] === "") {
            formIsValid = false;
            errors["confirm_password"] =  t("errors.confirm");
        } else if (fields["new_password"] !== fields["confirm_password"]) {
            formIsValid = false;
            errors["confirm_password"] =  t("errors.password_do_not_match");
            fields['new_password'] = "";
            fields['confirm_password'] = "";
        }
        
        setErrors(errors);
        setFieldsData(fields);
        return formIsValid;
    }

    function handleSubmit(e) {
        e.preventDefault();
        if (handleValidation()) {
            setLoading(true);
            const formData = fieldsData;
            fetch(Constants.API_CHANGE_PASSWORD, { 
                method: "POST", 
                body: JSON.stringify(formData), 
                headers: { 
                    "Authorization": "Bearer " + token
                }
            }) 
            .then(response => response.json()) 
            .then(
                data => {
                    setLoading(false);
                    dialog.show({
                        body: data.message,
                        actions: [
                            Dialog.OKAction(() => {
                                if (data.status) {
                                    removeLocalStorage();
                                    history.push("/login");
                                }
                            })
                        ],
                        bsSize: 'small'
                    });
                }
            ); 
        }
    }

    const handleHideShowPassword = (textbox, value, e) => {
        if (textbox === 'password') {
            setPasswordShow({
                hideShow: value,
                type: (value === "show") ? "text" : "password"
            })
        } else {
            setConfPasswordShow({
                hideShow: value,
                type: (value === "show") ? "text" : "password"
            })
        }
    }

    return (
        <>
            <Container className="mt-cust">
                <Row>
                    <Col md="3">
                        <div className="text-center">
                            {
                                (userData.profile_image !== null && userData.profile_image !== "") ? 
                                    <Image src={Constants.API_BASE_URL + userData.profile_image} thumbnail /> 
                                : 
                                    <Image src={profile_default} thumbnail /> 
                            }
                            <h6 className="mt-4">{
                                (userData) ? 
                                    <>
                                        {userData.first_name + " " + userData.last_name}
                                        <br/>
                                        <br/>
                                        <strong>User Login</strong>
                                        <p className="mt-2">{userData.email}</p>
                                    </>
                                : 
                                    ""
                                }</h6>
                        </div> 
                        <br/>
                        <ListGroup as="ul" style={{marginBottom: "5rem"}}>
                            <ListGroup.Item as="li">
                                <i className="fa fa-address-book" aria-hidden="true"></i>  
                                <a href="/personal-details"> Personal Details</a>
                            </ListGroup.Item>
                            <ListGroup.Item as="li">
                                <i className="fa fa-address-book" aria-hidden="true"></i> 
                                <a href="/business-details"> Business Details</a>
                            </ListGroup.Item>
                            <ListGroup.Item as="li" className="div-active">
                                <i className="fa fa-lock" aria-hidden="true"></i> 
                                <a href="/change-password"> Change Password</a>
                            </ListGroup.Item>
                        </ListGroup>                
                    </Col>
                    <Col md={9}>
                        <Card className="mb-5" style={{border: "none"}}>
                            <Card.Header style={{border: "none"}}>
                                <h2 className="text-success">
                                    Change Password
                                    <a href="/" className="btn btn-secondary float-right ml-2">
                                        <i className="fa fa-chevron-left"></i>  Back to Dashboard
                                    </a>
                                    <Button variant="primary" className="float-right ml-2" onClick={handleSubmit}>
                                        <i className="fa fa-floppy-o"></i> Save Changes
                                    </Button>
                                </h2>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col md={12}>
                                            <Form.Group>
                                                <Form.Label>Current Password <span className="text-danger">*</span></Form.Label>
                                                <Form.Control type="password" 
                                                    name="current_password"
                                                    value={fieldsData ? fieldsData.current_password : ""}
                                                    placeholder="Current Password"
                                                    onChange={handleChange}/>
                                                <Form.Text className="text-danger">{(!errors) ? "" : errors.current_password}</Form.Text>
                                            </Form.Group>
                                        </Col>  
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>New Password <span className="text-danger">*</span></Form.Label>
                                                <InputGroup>
                                                    <Form.Control type={passwordShow.type} 
                                                        name="new_password"
                                                        placeholder="New Password"
                                                        value={fieldsData ? fieldsData.new_password : ""}
                                                        onChange={handleChange}/>
                                                    <InputGroup.Prepend>
                                                        {
                                                            (passwordShow.hideShow == "hide") ?
                                                                <InputGroup.Text><i className="fa fa-eye-slash" onClick={(e) => handleHideShowPassword('password', 'show', e)}></i></InputGroup.Text>
                                                            :
                                                                <InputGroup.Text><i className="fa fa-eye" onClick={(e) => handleHideShowPassword('password', 'hide', e)}></i></InputGroup.Text>
                                                        }
                                                    </InputGroup.Prepend>
                                                </InputGroup>
                                                <Form.Text className="text-danger">{(!errors) ? "" : errors.new_password}</Form.Text>
                                            </Form.Group>
                                        </Col>  
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Confirm Password <span className="text-danger">*</span></Form.Label>
                                                <InputGroup>
                                                    <Form.Control type={confPasswordShow.type} 
                                                        name="confirm_password"
                                                        placeholder="Confirm Password"
                                                        value={fieldsData ? fieldsData.confirm_password : ""}
                                                        onChange={handleChange}
                                                        maxLength="15"/>
                                                    <InputGroup.Prepend>
                                                        {
                                                            (confPasswordShow.hideShow == "hide") ?
                                                                <InputGroup.Text><i className="fa fa-eye-slash"  onClick={(e) => handleHideShowPassword('confpassword', 'show', e)}></i></InputGroup.Text>
                                                            :
                                                                <InputGroup.Text><i className="fa fa-eye" onClick={(e) => handleHideShowPassword('confpassword', 'hide', e)}></i></InputGroup.Text>
                                                        }
                                                    </InputGroup.Prepend>
                                                </InputGroup>
                                                <Form.Text className="text-danger">{(!errors) ? "" : errors.confirm_password}</Form.Text>
                                            </Form.Group>
                                        </Col> 
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Loader show={loading}/>
        </>
    )
}

export default BusinessDetails;