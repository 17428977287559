import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { Image, Button } from "react-bootstrap";
import Moment from 'moment';
import Dialog from "react-bootstrap-dialog";
import { AlertContext } from "../context/Alert";
import Loader from '../header/Loader';
import  * as Constants from "../../config/Constants";
import { getToken } from "../../helpers/Session"; 

import sos_logo from "../../assets/image/sos-logo.png";
import './payment.scss';

var converter = require('number-to-words');

function PaymentReceipt() {
    const { dialog } = React.useContext(AlertContext);
    let { quotationId } = useParams();
    const token = getToken();    
    const [loading, setLoading] = useState(false);
    const [receiptData, setReceiptData] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState(null);
    useEffect(() => {
        fetchPaymentReceiptData();
    }, []);

    const fetchPaymentReceiptData = () => {
        setLoading(true);
        fetch(Constants.API_QUOTATION + quotationId, { 
            method: "GET",
            headers: { 
                "Authorization": "Bearer " + token
            }
        }) 
        .then(response => response.json())
        .then(resData => {
                setLoading(false);
                setReceiptData(resData.quotationData);
                const payDetails = JSON.parse(resData.quotationData.payment_details);
                setPaymentDetails(payDetails);
            }
        );
    };

    return (
        <div className="container">
            <div className="row" style={{width: "800px", border: "solid 1px", margin: "20px auto 60px auto", paddingBottom: "5px"}}>

                {/* <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center" style={{backgroundColor: "#000", color: "#fff", fontSize: "20px", margin: "0px 0px 6px 0px"}} id="dvIdSchoolRegNumber">
                    <p style={{marginTop: "0.5rem", marginBottom: "0.5rem"}}> Second Opinion Service </p> 
                </div> */}

                <div className="mt-2 col-xs-2 col-sm-2 col-md-2 col-lg-2">
                    {/* <img id="imgLogoSchool" className="pull-right" src="https://schooladmin.headwaytechies.com/attachments//school1.jpg" style={{height: "50px"}} /> */}
                </div>

                <div className=" mt-2 col-xs-8 col-sm-8 col-md-8 col-lg-8 text-center">
                    <div> 
                        <Image src={sos_logo} style={{width: "262px"}} />
                        {/* <h3 className="text-center" style={{margin: "0px"}} id="dvIdSchoolName"> Second Opinion Service </h3> */}
                    </div>
                    <div className="col-md-12 text-center" style={{padding: "0px", fontSize: "12px"}} id="dvIdSchoolAddress">
                        <i className="fa fa-map-marker fa-fw"></i> <b>Address: </b> 9 Mangoe Lane 3rd Floor, Room No 1 , Kolkata - 700001 India
                    </div>

                    <div className="col-md-12 row justify-content-center">
                        <div className="col-md-12 text-center" style={{fontSize: "12px"}}> 
                            <i className="fa fa-phone fa-fw"></i><b> Phone: </b> &nbsp;
                            <i style={{fontStyle: "normal", fontSize: "12px"}} id="dvIdSchoolPhoneNumber">+91 98310 91962 / +91 33 40070578</i>
                        </div>
                    </div>
                    <div className="col-md-12 row justify-content-center">
                        <div className="col-md-12 text-center" style={{fontSize: "12px"}}> 
                            <i className="fa fa-envelope fa-fw"></i><b> Email: </b> &nbsp;
                            <i style={{fontStyle: "normal", fontSize: "12px"}} id="dvIdSchoolPhoneNumber">contact@secondopinionservices.in</i>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{borderTop: "solid 1px", margin: "5px 0px 5px 0px"}}> 
                </div>

                <div className="col-4" style={{fontSize: "15px"}}>
                    <div> <b> Receipt No: </b> <i style={{fontStyle: "normal"}} id="iIdPaymentReceiptNo">{(receiptData) ? receiptData.payment_receipt_no : ""}</i>
                    </div> 
                </div>
                <div className="col-4 text-center">
                </div>
                <div className="col-4" style={{fontSize: "15px"}}>
                    <div className="text-right"> 
                        <b> Date: </b> <i style={{fontStyle: "normal"}}>{ Moment().format("DD-MM-YYYY ") }</i> 
                    </div>
                </div> <br/>

                <div className="col-12" style={{marginTop: "5px", fontSize: "15px"}}>
                    <b> Received From <span style={{color: "red"}}>*</span> : </b>
                    <i style={{fontStyle: "normal", marginLeft: "3px"}}>Second Opinion Service</i>
                </div>
 
                <div className="col-12" style={{marginTop: "5px", fontSize: "15px"}}>
                    <div className="row">
                        <div className="col-8">
                            <b> Amount <span style={{color: "red"}}>*</span> : </b>{paymentDetails ? converter.toWords(paymentDetails.payment_amount) + " rupees only" : "--"}
                        </div>
                        {/* <div className="col-4">
                            &nbsp; <b> on account of </b>
                        </div> */}
                    </div>
                </div><br/>

                {/* <div style={{marginTop: "5px"}} className="col-12">
                    <div className="row">
                        <div className="col-4">
                            <b> Admission Fees: </b>
                            ₹<i style={{fontStyle: "normal", marginLeft: "3px"}} id="iIdAdmissionFees">500</i>
                        </div>
                        <div className="col-4">
                            <b> Tuition Fees: </b>
                            ₹<i style={{fontStyle: "normal", marginLeft: "3px"}} id="iIdTuitionFees">5000</i>
                        </div>
                        <div className="col-4">
                            <b> Other Fees: </b>
                            ₹<i style={{fontStyle: "normal", marginLeft: "3px"}} id="iIdOtherFees">500</i>
                        </div>
                    </div>
                </div> */}

                <div className="col-12" style={{marginTop: "5px", fontSize: "15px"}}>
                    <div className="row">
                        <div className="col-12">
                            <div className="card border-dark">
                                <div className="card-body py-1">
                                    {
                                        (receiptData) ?
                                            <>
                                                <b>Payment: </b>
                                                ₹{(paymentDetails) ? parseFloat(paymentDetails.payment_amount).toFixed(2) : parseFloat(receiptData.amount).toFixed(2)} 
                                                - {receiptData.payment_mode} 
                                                {(receiptData.payment_mode == "Cheque" && paymentDetails) ? "(" + paymentDetails.cheque_no + ")" : ""}
                                                {(receiptData.payment_mode == "NEFT" && paymentDetails) ? "(Ref: " + paymentDetails.neft_reference_number + ")" : ""}
                                                <br/>
                                                {
                                                    (receiptData.payment_mode == "Cheque" && paymentDetails) ?
                                                        <><b>Bank Name: </b> {paymentDetails.bank_name + " (" + paymentDetails.bank_branch + ")"} <br/></>
                                                    :
                                                        ""
                                                }
                                                <b>Date: </b> {Moment(receiptData.payment_date).format('DD-MM-YYYY')}

                                            </>
                                        :   
                                            <>
                                                <b>Payment: </b> -- <br/>
                                            </>
                                    }
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-6 installmentPayDetails2">
                            <div className="card border-dark">
                            <div className="top-right" style={{
                                    position: "absolute",
                                    top: "-1px",
                                    right: "-1px",
                                    border: "1px solid",
                                    borderRadius: "2px",
                                    width: "20px"}}> &nbsp;2
                                </div>
                                <div className="card-body py-1">
                                    <b>Payment: </b>₹<i style={{fontStyle: "normal" }} id="iIdPaymentMode2">2000 - Cash</i><br />
                                    <b><label id="labelBankName2" style={{marginBottom: "0px", display: "none"}}>Bank Name:</label> </b><i style={{fontStyle: "normal"}} id="iIdBankName2"></i><br id="br2_1" style={{display: "none"}} />
                                    <b>Date: </b><i style={{fontStyle: "normal"}} id="iIdChequeDate2">15-07-2020</i>
                                    &nbsp;&nbsp;&nbsp;<span id="discountInstallmentId2" style={{display: "none"}}><b>Discount: </b> <i className="fa fa-inr fa-fw" aria-hidden="true"></i><i style={{fontStyle: "normal"}} id="discountInstallment2"></i></span>
                                </div>
                            </div>
                            <div className="small" id="subject2" style={{display: "none"}}><sup>*</sup> subject to realisation of cheque
                            </div>
                        </div> */}
                        <div className="col-6">
                            <div className="card border-white">
                                <div className="card-body">
                                    <p style={{marginBottom: "1px"}}> <b> Total Amount: </b> &nbsp;
                                        <span className="border border-dark rounded" style={{marginLeft: "34px", marginBottom: "1px", width: "35%", display: "inline-block", padding: "1px", textAlign: "right"}}>
                                            <i className="fa fa-inr fa-fw" aria-hidden="true"></i> <b id="bIdTotalAmount">{(receiptData) ? parseFloat(receiptData.amount).toFixed(2) : ""}</b>
                                        </span> <br/>
                                    </p>
                                    <p style={{marginBottom: "1px"}}> <b> Amount Received: </b> &nbsp;
                                        <span className="border border-dark rounded" style={{marginLeft: "3px", marginBottom: "1px", width: "35%", display: "inline-block", padding: "1px", textAlign: "right"}}>
                                            <i className="fa fa-inr fa-fw" aria-hidden="true"></i> <b id="bIdAmountReceived">{paymentDetails ? parseFloat(paymentDetails.payment_amount).toFixed(2) : ""}</b>
                                        </span> <br/>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
{/*
                <div className="border border-white rounded" style="margin-left:235px; margin-top: -65px; width:30%; padding:0px; display: none;">
                    <p style={{marginBottom: "1px"}}> <b> 1st Installment: </b> &nbsp;
                        <span className="border border-dark rounded" style="margin-bottom: 1px; margin-left: 10px; width:35%; display: inline-block; padding:1px;">
                            <i className="fa fa-inr fa-fw" aria-hidden="true"></i> <b id="bIdFirstInstallment">6000</b>
                        </span> <br/> </p>

                    <p style={{marginBottom: "1px"}}> <b> 2nd Installment: </b> &nbsp;
                        <span className="border border-dark rounded" style="margin-left: 3px; margin-bottom: 1px; width:35%; display: inline-block; padding:1px;">
                            <i className="fa fa-inr fa-fw" aria-hidden="true"></i> <b id="bIdSecondInstallment">6000</b>
                        </span> <br/> </p>

                    <p style={{marginBottom: "1px"}}> <b> 3rd Installment: </b> &nbsp;
                        <span className="border border-dark rounded" style="margin-left: 5px; margin-bottom: 1px; width:35%; display: inline-block; padding:1px;">
                            <i className="fa fa-inr fa-fw" aria-hidden="true"></i> <b id="bIdThirdInstallment">6000</b>
                        </span> <br/> </p>
                </div>

*/}
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{borderTop: "solid 1px", paddingTop: "20px", fontSize: "15px"}}>
                    <div className="row">
                        <div className="col-6 text-center">
                            <span>-----------------------</span><br/>
                            <span>(Admin Signature)</span>
                        </div>
                        <div className="col-6 text-center">
                            <span>-----------------------</span><br/>
                            <span>(Client Signature)</span>
                        </div>
                    </div>
                </div> 
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <Button cl onClick={() => window.print()}>Print</Button>
            </div>

        </div>
    );
}

export default PaymentReceipt;